import Badge from '@/components/V3/Badge';
import { Button } from '@/components/V3/Button';
import AppHeader from '@/containers/V3/AppHeader/AppHeader';
import useWebview from '@/hooks/V3/useWebview';
import { gameScoreState, safeAreaInsetsState } from '@/store/atom';
import { useRecoilValue } from 'recoil';
import GameMachine from '@/assets/images/V3/GameMachine.png';
import ShareIcon from '@/assets/icons/V3/ShareIcon.svg?react';
import RankingIcon from '@/assets/icons/V3/RankingIcon.svg?react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PersonalData, PersonalDataInitial } from '@/types/discover';
import { useAppSelector } from '@/redux/store';
import useCopy from '@/hooks/V3/useCopy';

const HighAndLowResult = () => {
  const user = useAppSelector((state) => state.user);
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);
  const { postMessage } = useWebview();
  const gameScore = useRecoilValue(gameScoreState);
  const [personalData, setPersonalData] = useState<PersonalData>(PersonalDataInitial);
  const { copyToClipboard } = useCopy();

  const getPersonalRankingHandler = () => {
    axios
      .get(`https://sellkey-worker.cplat.io/get-personal-rank?UserId=${user.StoreName}`)
      .then((res) => {
        setPersonalData(res.data.body);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getPersonalRankingHandler();
  }, []);

  return (
    <main
      className={`flex h-screen select-none flex-col items-center bg-[linear-gradient(180deg,_#E9F1FF_0%,_#FFE5E4_100%)] px-[20px]`}
      style={{
        paddingTop: safeAreaInsets.topInset,
        paddingBottom: safeAreaInsets.bottomInset,
      }}
    >
      <AppHeader title={'하이앤로우 게임'} />
      <div className="flex w-full flex-grow flex-col items-center overflow-y-auto pb-[24px] scrollbar-hide">
        <div className="mt-[20px] flex self-start">
          <Badge variant={'primary'} height={'26'} width={'6'}>
            최고 점수 : {personalData.Score}
          </Badge>
        </div>
        <div className="flex w-full flex-col items-center">
          <div className="mb-[12px] mt-[50px] text-gray-800 Title3S18">최종점수</div>
          <div className="flex items-center">
            <span className="text-[48px] font-bold leading-[48px]">{gameScore}</span>
            <span className="text-[32px] font-bold leading-[32px]">점</span>
          </div>
          <img
            src={GameMachine}
            alt={'GameMachine'}
            className="mb-[36px] mt-[48px] h-[170px] w-[160px]"
          />
          <div className="flex items-center gap-[12px]">
            <button
              className="px-auto flex w-[130px] items-center justify-center gap-[6px] rounded-[10px] bg-gray-50 py-[12px]"
              onClick={() => {
                copyToClipboard(
                  'https://sellerbox.onelink.me/S4GA/cibdum5x',
                  '링크가 복사되었어요!',
                );
              }}
            >
              <span>
                <ShareIcon />
              </span>
              <span className="text-gray-800 Body1S16">공유하기</span>
            </button>
            <button
              className="px-auto flex w-[130px] items-center justify-center gap-[6px] rounded-[10px] bg-gray-50 py-[12px]"
              onClick={() => {
                postMessage({
                  action: 'navigateApp',
                  data: {
                    key: 'HighAndLowRanking',
                    name: 'HighAndLowRanking',
                  },
                });
              }}
            >
              <span>
                <RankingIcon />
              </span>
              <span className="text-gray-800 Body1S16">명예의 전당</span>
            </button>
          </div>
        </div>
      </div>
      <Button
        variant={'SolidHigh'}
        width={'full'}
        height={'52'}
        onClick={() => {
          postMessage({
            action: 'resetApp',
            data: {
              index: 0,
              routes: [
                {
                  key: 'MainTab',
                  name: 'MainTab',
                  params: {
                    screen: 'DiscoverMain',
                  },
                },
                {
                  key: 'HighAndLowCategoryList',
                  name: 'HighAndLowCategoryList',
                },
              ],
            },
          });
        }}
      >
        한번 더 하기
      </Button>
    </main>
  );
};

export default HighAndLowResult;
