// 통계 리포트 탭 예시 데이터
export const salesInfoExample = {
  TotalSalesAmount: 12055110,
  TotalSalesCount: 563,
  TotalSalesShippingFee: 304500,
  TotalSalesDiscountAmount: 10400,
};
export const settleInfoExample = {
  TotalSettleAmount: 11918457,
  TotalSettleCompletionAmount: 11918457,
};
export const calendarDailyListExample = [
  {
    Date: '2025-01-01T00:00:00',
    SalesAmount: 596890,
    SalesCount: 31,
    SalesDiscountAmount: 0,
    SalesShippingFee: 12000,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-02T00:00:00',
    SalesAmount: 595000,
    SalesCount: 28,
    SalesDiscountAmount: 0,
    SalesShippingFee: 18000,
    SettleCompletionAmount: 566293,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-03T00:00:00',
    SalesAmount: 367470,
    SalesCount: 17,
    SalesDiscountAmount: 0,
    SalesShippingFee: 12000,
    SettleCompletionAmount: 1145119,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-04T00:00:00',
    SalesAmount: 294000,
    SalesCount: 15,
    SalesDiscountAmount: 0,
    SalesShippingFee: 6000,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-05T00:00:00',
    SalesAmount: 639300,
    SalesCount: 32,
    SalesDiscountAmount: 0,
    SalesShippingFee: 15000,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-06T00:00:00',
    SalesAmount: 435200,
    SalesCount: 20,
    SalesDiscountAmount: 0,
    SalesShippingFee: 6000,
    SettleCompletionAmount: 759483,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-07T00:00:00',
    SalesAmount: 527570,
    SalesCount: 28,
    SalesDiscountAmount: 0,
    SalesShippingFee: 15000,
    SettleCompletionAmount: 852142,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-08T00:00:00',
    SalesAmount: 600120,
    SalesCount: 31,
    SalesDiscountAmount: 600,
    SalesShippingFee: 12000,
    SettleCompletionAmount: 550068,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-09T00:00:00',
    SalesAmount: 453300,
    SalesCount: 21,
    SalesDiscountAmount: 0,
    SalesShippingFee: 12000,
    SettleCompletionAmount: 607196,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-10T00:00:00',
    SalesAmount: 610820,
    SalesCount: 24,
    SalesDiscountAmount: 0,
    SalesShippingFee: 6000,
    SettleCompletionAmount: 311374,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-11T00:00:00',
    SalesAmount: 489100,
    SalesCount: 27,
    SalesDiscountAmount: 600,
    SalesShippingFee: 15000,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-12T00:00:00',
    SalesAmount: 581300,
    SalesCount: 24,
    SalesDiscountAmount: 600,
    SalesShippingFee: 9000,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-13T00:00:00',
    SalesAmount: 1013200,
    SalesCount: 45,
    SalesDiscountAmount: 0,
    SalesShippingFee: 17500,
    SettleCompletionAmount: 944187,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-14T00:00:00',
    SalesAmount: 603600,
    SalesCount: 27,
    SalesDiscountAmount: 0,
    SalesShippingFee: 12000,
    SettleCompletionAmount: 1534591,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-15T00:00:00',
    SalesAmount: 738900,
    SalesCount: 31,
    SalesDiscountAmount: 0,
    SalesShippingFee: 15000,
    SettleCompletionAmount: 716427,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-16T00:00:00',
    SalesAmount: 589760,
    SalesCount: 30,
    SalesDiscountAmount: 4400,
    SalesShippingFee: 26500,
    SettleCompletionAmount: 572882,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-17T00:00:00',
    SalesAmount: 382300,
    SalesCount: 16,
    SalesDiscountAmount: 600,
    SalesShippingFee: 18000,
    SettleCompletionAmount: 669667,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-18T00:00:00',
    SalesAmount: 439600,
    SalesCount: 23,
    SalesDiscountAmount: 0,
    SalesShippingFee: 6000,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-19T00:00:00',
    SalesAmount: 637320,
    SalesCount: 24,
    SalesDiscountAmount: 0,
    SalesShippingFee: 12000,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-20T00:00:00',
    SalesAmount: 789380,
    SalesCount: 37,
    SalesDiscountAmount: 3600,
    SalesShippingFee: 42000,
    SettleCompletionAmount: 868492,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-21T00:00:00',
    SalesAmount: 550800,
    SalesCount: 26,
    SalesDiscountAmount: 0,
    SalesShippingFee: 11500,
    SettleCompletionAmount: 1259800,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-22T00:00:00',
    SalesAmount: 120180,
    SalesCount: 6,
    SalesDiscountAmount: 0,
    SalesShippingFee: 6000,
    SettleCompletionAmount: 570437,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-23T00:00:00',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: -9701,
  },
  {
    Date: '2025-01-24T00:00:00Z',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-25T00:00:00Z',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-26T00:00:00Z',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-27T00:00:00Z',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-28T00:00:00Z',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-29T00:00:00Z',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-30T00:00:00Z',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
  {
    Date: '2025-01-31T00:00:00Z',
    SalesAmount: 0,
    SalesCount: 0,
    SalesDiscountAmount: 0,
    SalesShippingFee: 0,
    SettleCompletionAmount: 0,
    SettleYetAmount: 0,
  },
];

// 통계 기간별 탭 예시 데이터
export const monthlySalesListExample = [
  {
    Month: '2024-07',
    FirstDayOfMonth: '2024-07-01T00:00:00',
    SalesAmount: 25475160,
    SalesAmountUntilDay: 25475160,
  },
  {
    Month: '2024-08',
    FirstDayOfMonth: '2024-08-01T00:00:00',
    SalesAmount: 22215990,
    SalesAmountUntilDay: 22215990,
  },
  {
    Month: '2024-09',
    FirstDayOfMonth: '2024-09-01T00:00:00',
    SalesAmount: 15904140,
    SalesAmountUntilDay: 15904140,
  },
  {
    Month: '2024-10',
    FirstDayOfMonth: '2024-10-01T00:00:00',
    SalesAmount: 18527260,
    SalesAmountUntilDay: 18527260,
  },
  {
    Month: '2024-11',
    FirstDayOfMonth: '2024-11-01T00:00:00',
    SalesAmount: 22732720,
    SalesAmountUntilDay: 22732720,
  },
  {
    Month: '2024-12',
    FirstDayOfMonth: '2024-12-01T00:00:00',
    SalesAmount: 19752370,
    SalesAmountUntilDay: 19752370,
  },
  {
    Month: '2025-01',
    FirstDayOfMonth: '2025-01-01T00:00:00',
    SalesAmount: 12272110,
    SalesAmountUntilDay: 12272110,
  },
];
export const activedMonthlySalesExample =
  monthlySalesListExample[monthlySalesListExample.length - 1];
export const activedMonthlySalesAmountExample = 136879750;
export const salesStoreListExample = [
  {
    MarketName: '스마트스토어',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketSmartstore.png',
    StoreName: '워치케치',
    SalesAmount: 65024500,
    SalesCount: 5550,
  },
  {
    MarketName: '샵바이',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketShopby.png',
    StoreName: 'sellerbox',
    SalesAmount: 33071250,
    SalesCount: 16,
  },
  {
    MarketName: '롯데온',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketLotteon.png',
    StoreName: '테스트',
    SalesAmount: 20519800,
    SalesCount: 6,
  },
  {
    MarketName: '스마트스토어',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketSmartstore.png',
    StoreName: '영양jerry',
    SalesAmount: 9254700,
    SalesCount: 1,
  },
  {
    MarketName: '쿠팡',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketCoupang.png',
    StoreName: '쿠팡스토어1',
    SalesAmount: 4056500,
    SalesCount: 10,
  },
  {
    MarketName: '11번가',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarket11st.png',
    StoreName: '11번가베스트',
    SalesAmount: 2276800,
    SalesCount: 8,
  },
  {
    MarketName: '위메프',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketWemakeprice.png',
    StoreName: '위메프스토어A',
    SalesAmount: 1184700,
    SalesCount: 12,
  },
  {
    MarketName: '티몬',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketTmon.png',
    StoreName: '티몬최저가',
    SalesAmount: 897500,
    SalesCount: 7,
  },
  {
    MarketName: '옥션',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketAuction.png',
    StoreName: '옥션특가',
    SalesAmount: 645300,
    SalesCount: 20,
  },
  {
    MarketName: 'G마켓',
    MarketIconUrl:
      'https://cplat-images.s3.ap-northeast-2.amazonaws.com/sellerbox/market-icon/IconMarketGmarket.png',
    StoreName: 'G마켓스토어B',
    SalesAmount: 420500,
    SalesCount: 15,
  },
];
