import { SafeAreaInsets } from '@/types/global';
import { inquiryCommentTemplateInitial } from '@/types/inquiry';
import { atom } from 'recoil';

export const templateInquiryState = atom({
  key: 'templateInquiryState',
  default: inquiryCommentTemplateInitial,
});

/** V3 */
export const safeAreaInsetsState = atom<SafeAreaInsets>({
  key: 'safeAreaInsetsState',
  default: {
    topInset: 0,
    bottomInset: 0,
  },
});

export const gameCategoryState = atom({
  key: 'gameCategoryState',
  default: '전체',
});

export const gameScoreState = atom({
  key: 'gameScoreState',
  default: '0',
});

export const recentSearchKeywordListAsyncStorageState = atom<Array<string>>({
  key: 'recentSearchKeywordListAsyncStorageState',
  default: [],
});
