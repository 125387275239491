import Badge from '@/components/V3/Badge';
import { Button } from '@/components/V3/Button';
import AppHeader from '@/containers/V3/AppHeader/AppHeader';
import { safeAreaInsetsState } from '@/store/atom';
import { useRecoilValue } from 'recoil';
import GameMachine from '@/assets/images/V3/GameMachine.png';
import InfoFillGrayIcon from '@/assets/icons/InfoFillGrayIcon.svg?react';
import useWebview from '@/hooks/V3/useWebview';

const HighAndLowStart = () => {
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);
  const { postMessage } = useWebview();

  return (
    <main
      className={`flex h-screen select-none flex-col items-center bg-[linear-gradient(180deg,_#E9F1FF_0%,_#FFE5E4_100%)] px-[20px]`}
      style={{
        paddingTop: safeAreaInsets.topInset,
        paddingBottom: safeAreaInsets.bottomInset,
      }}
    >
      <AppHeader title={'하이앤로우 게임'} />
      <div className="flex w-full flex-grow flex-col items-center overflow-y-auto pb-[24px] scrollbar-hide">
        <div className="mt-[42px] flex flex-col items-center gap-[6px] text-center">
          <Badge variant={'primary'} height={'26'} width={'6'}>
            Mini Game
          </Badge>
          <div className="text-v3-gray-900 Title2B20">어떤 키워드가 더 인기가 있을까요?</div>
          <div className="whitespace-pre text-v3-gray-700 Body6M14">{`게임을 하면서 소비자 키워드를 찾아보세요\n연속 득점 기록도 세워보세요`}</div>
        </div>
        <img
          src={GameMachine}
          alt={'GameMachine'}
          className="mb-[44px] mt-[36px] h-[258px] w-[237px]"
        />
        <div className="flex w-full flex-col gap-[6px] rounded-[10px] bg-[rgba(255,255,255,0.4)] p-[10px]">
          <div className="flex items-center gap-[8px]">
            <span>
              <InfoFillGrayIcon />
            </span>
            <span className="text-v3-gray-800 Body5S14">게임방법</span>
          </div>
          <ul className="list-disc pl-[22px] text-v3-gray-750 Caption1M12">
            <li>두 개의 키워드 중 검색량이 높은 키워드를 선택하세요</li>
            <li>정답을 맞출 경우, 계속 문제를 풀 수 있어요</li>
            <li>연속 득점 기록에 도전하세요</li>
          </ul>
        </div>
      </div>
      <Button
        variant={'SolidHigh'}
        width={'full'}
        height={'52'}
        onClick={() => {
          postMessage({
            action: 'navigateApp',
            data: {
              key: 'HighAndLowCategoryList',
              name: 'HighAndLowCategoryList',
            },
          });
        }}
      >
        게임 시작하기
      </Button>
    </main>
  );
};

export default HighAndLowStart;
