const BlogItemSkeleton = () => {
  return (
    <div className="flex animate-pulse items-center gap-[12px]">
      <div className="h-[80px] w-[120px] rounded-[10px] bg-gray-200"></div>
      <div className="flex flex-grow flex-col gap-[6px]">
        <div className="h-[12px] w-[40%] rounded bg-gray-200"></div>
        <div className="h-[14px] w-full rounded bg-gray-200"></div>
        <div className="h-[14px] w-[80%] rounded bg-gray-200"></div>
      </div>
    </div>
  );
};

export default BlogItemSkeleton;
