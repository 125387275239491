import { Button } from '@/components/V3/Button';
import EmptyFaceIcon from '@/assets/icons/EmptyFaceIcon.svg?react';

interface BlogErrorContainerProps {
  onClick: () => void;
}

const BlogErrorContainer = ({ onClick }: BlogErrorContainerProps) => {
  return (
    <div className="my-[40px] flex flex-col items-center gap-[20px]">
      <span>
        <EmptyFaceIcon />
      </span>
      <div className="BodyM14">콘텐츠를 불러오는 데 문제가 발생했습니다.</div>
      <Button onClick={onClick} variant={'SolidLow'} height={'44'} width={'10'}>
        다시 시도
      </Button>
    </div>
  );
};

export default BlogErrorContainer;
