import ArrowGrayLeftMore from '@/assets/icons/V3/arrow/ArrowGrayLeftMore.svg?react';
import ArrowGrayRightMore from '@/assets/icons/V3/arrow/ArrowGrayRightMore.svg?react';
import ReportAccordionContainer from './_containers/ReportAccordionContainer';
import IconBannerContainer from '@/containers/V3/Banner/IconBannerContainer';
import BottomSheet from '@/containers/V3/BottomSheet/BottomSheet';
import RegisteredEmailModalContainer from './_containers/RegisteredEmailModalContainer';
import NotRegisteredEmailModalContainer from './_containers/NotRegisteredEmailModalContainer';
import CalendarDetailModalContainer from './_containers/CalendarDetailModalContainer';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import useWebview from '@/hooks/V3/useWebview';
import { CalendarDaily, SalesInfo, SettleInfo } from '@/types/statistics';
import { formatKSTtoYearMonth } from '@/utils/parser';
import { StatisticsCalendar } from '@/components/V3/Calendar/StatisticsCalendar';
import { getActivePeriod, handleMoveMonth } from '../_utils';
import { cn } from '@/utils/tailwind';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { setUser } from '@/redux/userSlice';
import { useToast } from '@/hooks/V3/useToast';

interface StatisticsReportContainerProps {
  activedDate: string;
  setActivedDate: Dispatch<SetStateAction<string>>;
  salesInfo: SalesInfo;
  settleInfo: SettleInfo;
  calendarDailyList: Array<CalendarDaily>;
}

const StatisticsReportContainer = ({
  activedDate,
  setActivedDate,
  salesInfo,
  settleInfo,
  calendarDailyList,
}: StatisticsReportContainerProps) => {
  const bottomSheetRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.user);
  const [isExcelBottomSheetOpen, setIsExcelBottomSheetOpen] = useState<boolean>(false);
  const [isCalendarDetailBottomSheetOpen, setIsCalendarDetailBottomSheetOpen] =
    useState<boolean>(false);
  const { postMessage } = useWebview();
  const activeStartDate = new Date(getActivePeriod(activedDate, 1, true).dateFrom);
  const [selectedDetailDay, setSelectedDetailDay] = useState<Date>(new Date());
  const dispatch = useAppDispatch();
  const { showToast } = useToast();

  const { mutate: getUserInfo } = usePost(CPLAT_API_ROUTES.getUserInfo);

  const handleGetUserInfo = () => {
    getUserInfo(
      {
        cplatToken: user.CplatToken,
      },
      {
        onSuccess(res) {
          const { data, code } = res.data as CplatApiResponse<UserData[]>;
          if (code === '200') {
            dispatch(setUser(data[0]));
          }
        },
        onError(error) {
          showToast('warning', error.message);
          console.error(error);
        },
      },
    );
  };

  const handleExcelBottomSheet = (open: boolean) => {
    setIsExcelBottomSheetOpen(open);
    // web bottom sheet 활성화 시, tab 이동 방지를 위해 활성화 여부값 전달
    postMessage({
      action: 'isWebBottomSheetOpen',
      data: String(open),
    });
  };

  const handleCalendarDetailBottomSheet = (open: boolean) => {
    setIsCalendarDetailBottomSheetOpen(open);
    // web bottom sheet 활성화 시, tab 이동 방지를 위해 활성화 여부값 전달
    postMessage({
      action: 'isWebBottomSheetOpen',
      data: String(open),
    });
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      // android app 에서 back event 발생 시, bottom sheet 닫기
      if (data['isWebBottomSheetOpen']) {
        handleExcelBottomSheet(false);
        handleCalendarDetailBottomSheet(false);
      }

      // app에서 메시지 오면 user info 재호출
      if (data['getUserInfo']) {
        handleGetUserInfo();
      }
    };

    window.addEventListener('message', handleMessage);
    document.addEventListener('message', handleMessage as EventListener);
    return () => {
      window.removeEventListener('message', handleMessage);
      document.addEventListener('message', handleMessage as EventListener);
    };
  }, []);

  return (
    <>
      <div className="relative flex flex-grow flex-col overflow-y-auto px-[20px] scrollbar-hide">
        <div className="relative flex w-fit items-center gap-[8px] bg-white pb-[6px] pt-[24px]">
          <span
            className="absolute left-[-8px] flex h-[32px] w-[32px] items-center justify-center"
            onClick={() =>
              handleMoveMonth({
                action: 'down',
                activedDate,
                setActivedDate,
              })
            }
          >
            <ArrowGrayLeftMore />
          </span>
          <div className="px-[24px] text-gray-800 Title3S18">
            {formatKSTtoYearMonth(activedDate)}
          </div>
          <span
            className="absolute right-[-8px] flex h-[32px] w-[32px] items-center justify-center"
            onClick={() =>
              handleMoveMonth({
                action: 'up',
                activedDate,
                setActivedDate,
              })
            }
          >
            <ArrowGrayRightMore />
          </span>
        </div>

        <ReportAccordionContainer title="매출" data={salesInfo} />
        <div className="h-[1px] w-full flex-none bg-gray-150"></div>
        <ReportAccordionContainer title="정산" data={settleInfo} />
        {!user.EmailAddreess && (
          <IconBannerContainer
            title={'매출 데이터 받아보기'}
            description="이메일을 등록하면 엑셀 파일을 발송해 드려요"
            onClick={() => {
              handleExcelBottomSheet(true);
            }}
            className="mb-[24px] mt-[4px]"
          />
        )}
        <div className="mb-[10px]">
          <div className="flex items-center gap-[12px]">
            <div className="flex items-center gap-[4px]">
              <div className="h-[8px] w-[8px] rounded-[4px] bg-primary-500"></div>
              <span className="text-gray-750 Caption1M12">매출</span>
            </div>
            <div className="flex items-center gap-[4px]">
              <div className="h-[8px] w-[8px] rounded-[4px] bg-gray-600"></div>
              <span className="text-gray-750 Caption1M12">정산</span>
            </div>
          </div>
        </div>
        <StatisticsCalendar
          activeStartDate={activeStartDate}
          calendarDailyList={calendarDailyList}
          onClickDay={(date: Date) => {
            setSelectedDetailDay(date);
            handleCalendarDetailBottomSheet(true);
          }}
          className={cn(!user.EmailAddreess && 'mb-[38px]')}
        />
        {user.EmailAddreess && (
          <IconBannerContainer
            title={user.AdAgreeYn === 'Y' ? '이메일 등록 완료!' : '매출 데이터 받아보기'}
            description={
              user.AdAgreeYn === 'Y'
                ? '매출 파일을 발송해 드릴게요'
                : '마케팅 정보 수신 동의가 필요해요'
            }
            onClick={() => {
              handleExcelBottomSheet(true);
            }}
            className="my-[24px]"
          />
        )}
      </div>
      <BottomSheet
        bottomSheetRef={bottomSheetRef}
        open={isExcelBottomSheetOpen}
        onClose={() => handleExcelBottomSheet(false)}
      >
        {user.EmailAddreess ? (
          <RegisteredEmailModalContainer onClose={() => handleExcelBottomSheet(false)} />
        ) : (
          <NotRegisteredEmailModalContainer onClose={() => handleExcelBottomSheet(false)} />
        )}
      </BottomSheet>
      <BottomSheet
        bottomSheetRef={bottomSheetRef}
        open={isCalendarDetailBottomSheetOpen}
        onClose={() => handleCalendarDetailBottomSheet(false)}
        className="max-h-[618px] min-h-[328px] px-0 pb-0 pt-[24px]"
      >
        <CalendarDetailModalContainer selectedDetailDay={selectedDetailDay} />
      </BottomSheet>
    </>
  );
};

export default StatisticsReportContainer;
