import AppHeader from '@/containers/V3/AppHeader/AppHeader';
import { CATEGORY_KEYWORD_LIST } from './_const';
import useWebview from '@/hooks/V3/useWebview';
import { useRecoilValue } from 'recoil';
import { safeAreaInsetsState } from '@/store/atom';

const HighAndLowCategoryList = () => {
  const { postMessage } = useWebview();
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);

  return (
    <main
      className="flex h-screen select-none flex-col gap-[12px] bg-white px-[20px]"
      style={{
        paddingTop: safeAreaInsets.topInset,
        paddingBottom: safeAreaInsets.bottomInset,
      }}
    >
      <AppHeader title={'하이앤로우 게임'} />
      <div className="flex w-full flex-grow flex-col items-center overflow-y-auto pb-[24px] scrollbar-hide">
        <div className="my-[20px] text-v3-gray-900 Title3S18">카테고리를 선택하세요</div>
        <div className="flex w-full flex-col gap-[8px]">
          {CATEGORY_KEYWORD_LIST.map((item) => (
            <div
              key={item.title}
              className="flex w-full items-center gap-[12px] rounded-[10px] bg-gray-100 p-[10px]"
              onClick={() => {
                postMessage({
                  action: 'navigateApp',
                  data: {
                    key: 'HighAndLowGame',
                    name: 'HighAndLowGame',
                    params: {
                      gameCategory: item.title,
                    },
                  },
                });
              }}
            >
              <span>
                <item.icon />
              </span>
              <span className="text-v3-gray-700 Body1S16">{item.title}</span>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default HighAndLowCategoryList;
