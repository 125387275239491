import { cn } from '@/utils/tailwind';
import { Dispatch, SetStateAction } from 'react';
import { motion } from 'framer-motion';

interface TabProps<T extends string> {
  variant: 'underline' | 'button';
  itemList: Array<T>;
  activedItem: T;
  setActivedItem: Dispatch<SetStateAction<T>>;
  indicatorClassname?: string; // indicator width 조절용
}

const Tab = <T extends string>({
  variant,
  itemList,
  activedItem,
  setActivedItem,
  indicatorClassname,
}: TabProps<T>) => {
  switch (variant) {
    case 'underline': {
      const percent = 100 / itemList.length; // 각 아이템의 위치 계산
      return (
        <div className="relative flex justify-center border-b-[1px] border-gray-200 Title3S18">
          {itemList.map((item) => (
            <div
              key={item}
              className={cn(
                'relative flex w-full justify-center pb-[12px] pt-[14px]',
                activedItem === item ? 'text-gray-700' : 'text-gray-600',
              )}
              onClick={() => setActivedItem(item)}
            >
              <span>{item}</span>
            </div>
          ))}
          <motion.div
            className={cn('absolute bottom-0 h-[2px] w-[138px] bg-gray-700', indicatorClassname)}
            layout
            initial={false}
            animate={{
              left: `${itemList.indexOf(activedItem) * percent + percent / 2}%`, // 현재 위치 계산
              transform: `translateX(-50%)`, // 중앙 정렬
            }}
            transition={{ type: 'spring', stiffness: 400, damping: 40 }}
          />
        </div>
      );
    }
    case 'button': {
      const buttonWidth = `calc((100% - 16px) / ${itemList.length})`; // 버튼 너비 계산
      return (
        <div className="relative flex h-[50px] gap-[4px] rounded-[12px] bg-gray-150 p-[4px]">
          {itemList.map((item) => (
            <div
              key={item}
              className="relative flex w-full items-center justify-center"
              onClick={() => setActivedItem(item)}
            >
              <span
                className={cn(
                  'Body5S14 z-1',
                  activedItem === item ? 'text-v3-gray-700' : 'text-gray-500',
                )}
              >
                {item}
              </span>
            </div>
          ))}
          <motion.div
            className={cn('absolute top-[4px] h-[42px] rounded-[8px] bg-white', indicatorClassname)}
            style={{ width: buttonWidth }} // Indicator의 너비를 버튼 너비와 동일하게 설정
            layout
            initial={false}
            animate={{
              // 전체 width를 '100% - 16px' 기준으로 left 계산
              left: `calc(${itemList.indexOf(activedItem)} * (${buttonWidth} + 4px) + 4px)`,
            }}
            transition={{ type: 'spring', stiffness: 600, damping: 40 }}
          />
        </div>
      );
    }
  }
};

export default Tab;
