import { cn } from '@/utils/tailwind';
import { VariantProps, cva } from 'class-variance-authority';

const badgeVariants = cva('flex items-center justify-center w-fit relative', {
  variants: {
    variant: {
      primary: 'text-primary-500 bg-primary-100',
      gray: 'text-v3-gray-550 bg-v3-gray-150',
      red: 'text-red-400 bg-red-100',
      blue: 'text-blue-400 bg-blue-100',
      green: 'text-green-400 bg-green-100',
    },
    // 텍스트 세로 가운데 정렬을 위한 !leading-[1px] 추가
    height: {
      '30': 'h-[30px] Body6M14 rounded-[4px] !leading-[1px]',
      '26': 'h-[26px] Caption2B12 rounded-[4px] !leading-[1px]',
      '22': 'h-[22px] Caption2B12 rounded-[4px] !leading-[1px]',
    },
    width: {
      '8': 'px-[8px]',
      '6': 'px-[6px]',
      '4': 'px-[4px]',
    },
  },
  defaultVariants: {
    height: '30',
    variant: 'primary',
    width: '8',
  },
});
export type BadgeProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants> & {
    hasFurtherAction?: boolean;
  };

const Badge = ({
  className,
  variant,
  height,
  width,
  children,
  hasFurtherAction = false,
  ...props
}: BadgeProps) => {
  return (
    <div className={cn(badgeVariants({ variant, height, width }), className)} {...props}>
      {children}
      {hasFurtherAction && (
        <div
          className={cn(
            'absolute right-0 top-0 bg-red-400',
            height === '30' ? 'h-[6px] w-[6px] rounded-[3px]' : 'h-[4px] w-[4px] rounded-[2px]',
          )}
        ></div>
      )}
    </div>
  );
};

export default Badge;
