import ArrowGrayBottomMore from '@/assets/icons/V3/arrow/ArrowGrayBottomMore.svg?react';
import SalesIcon from '@/assets/icons/V3/statistics/SalesIcon.svg?react';
import AdjustmentIcon from '@/assets/icons/V3/statistics/AdjustmentIcon.svg?react';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { SalesInfo, SettleInfo } from '@/types/statistics';
import { autoComma } from '@/utils/parser';
import { cn } from '@/utils/tailwind';

interface ReportAccordionContainerProps {
  title: '매출' | '정산';
  data: SalesInfo | SettleInfo;
  className?: string;
}

// data의 SalesInfo 타입 여부로, 타입 좁히기를 해주는 타입 가드 함수
const isSalesInfo = (data: SalesInfo | SettleInfo): data is SalesInfo => {
  return 'TotalSalesAmount' in data;
};

const ReportAccordionContainer = ({ title, data, className }: ReportAccordionContainerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={cn('flex items-center justify-between py-[20px]', className)}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex w-full justify-between">
        <div>
          <span>{title === '매출' ? <SalesIcon /> : <AdjustmentIcon />}</span>
        </div>
        <div className="ml-[8px] mr-[14px] flex w-full flex-grow flex-col gap-[8px]">
          <div className="flex w-full justify-between">
            <span className="text-gray-800 Body2M16">{title}</span>
            {title === '매출' && isSalesInfo(data) ? (
              <span className="text-gray-800 Body1S16">
                {`${autoComma(data.TotalSalesAmount)}원 (${autoComma(data.TotalSalesCount)}건)`}
              </span>
            ) : (
              !isSalesInfo(data) && (
                <span className="text-gray-800 Body1S16">{`${autoComma(
                  data.TotalSettleAmount,
                )}원`}</span>
              )
            )}
          </div>
          <motion.div
            className="flex flex-col gap-[8px] overflow-hidden"
            initial={{ height: 0, opacity: 0 }}
            animate={isOpen ? { height: 'auto', opacity: 1 } : { height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {title === '매출' && isSalesInfo(data) ? (
              <>
                <div className="flex w-full justify-between text-v3-gray-750 Body6M14">
                  <span>배송비</span>
                  <span>{autoComma(data.TotalSalesShippingFee)}원</span>
                </div>
                <div className="flex w-full justify-between text-v3-gray-750 Body6M14">
                  <span>할인금액</span>
                  <span>-{autoComma(data.TotalSalesDiscountAmount)}원</span>
                </div>
              </>
            ) : (
              !isSalesInfo(data) && (
                <>
                  <div className="flex w-full justify-between text-v3-gray-750 Body6M14">
                    <span>완료</span>
                    <span>{autoComma(data.TotalSettleCompletionAmount)}원</span>
                  </div>
                  <div className="flex w-full justify-between text-v3-gray-750 Body6M14">
                    <span>예정</span>
                    <span>{autoComma(data.TotalSettleAmount)}원</span>
                  </div>
                </>
              )
            )}
          </motion.div>
        </div>
        <div className="pt-[4px]">
          <motion.div animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
            <ArrowGrayBottomMore />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ReportAccordionContainer;
