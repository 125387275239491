import { useRef, useState, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import BubbleContainer from './_components/BubbleContainer';
import Input from '@/components/atoms/Input';
import { cn } from '@/utils/tailwind';
import BotBubble from './_components/BotBubble';
import { Message, ProductName } from './ChangeNamePage';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/redux/store';
import { useToast } from '@/hooks/useToast';
import { cplatApiV2Sellkey } from '@/api';

const MakeNamePage = () => {
  const [searchParams] = useSearchParams();
  const cplatToken = searchParams.get('cplatToken');
  const user = useAppSelector((state) => state.user);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [storedMessages, setStoredMessages] = useLocalStorage<Message[]>('make_name_messages', [
    {
      id: 0,
      text: '키워드와 상품 특징을 입력해보세요.\nAI가 상품명을 만들어드려요.\n예시) 인테리어 블루투스 스피커',
      sender: 'bot',
    },
  ]);
  const [textareaHeight, setTextareaHeight] = useState<number>(64 - 22);

  // 24시간 지난 메시지 제거하고 보여주기
  const filteredMessages = storedMessages.filter((storedMessage: Message) => {
    if (storedMessage.id === 0) return true;
    const isWithin24Hours = new Date().getTime() - storedMessage.id <= 86400000;
    return isWithin24Hours;
  });

  const [messages, setMessages] = useState<Message[]>(filteredMessages);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isActive = !isLoading && input.trim();
  const { toast } = useToast();
  const [productNameListData, setProductNameListData] = useState<Array<ProductName>>([]);

  const botMessages = messages.filter((message) => message.sender === 'bot');
  const latestBotMessageId =
    botMessages.length > 0 ? Math.max(...botMessages.map((msg) => msg.id)) : null;

  const getProductName = async () => {
    try {
      setIsLoading(true);
      const {
        data: { code, data },
      } = await cplatApiV2Sellkey.post('sellkey/get_product_name_maker', {
        CplatToken: cplatToken || user.CplatToken,
        Keyword: input.replace('\n', ''),
      });

      if (code === '200') {
        const { ProductNameList } = data[0];
        const parsedList = ProductNameList.map((item: string) => {
          return { product_name: item };
        });
        setProductNameListData(parsedList);
        const botMessage: Message = {
          id: Date.now() + 1,
          text: ProductNameList[0],
          sender: 'bot',
          remainCount: 4,
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } else {
        inputRef.current && inputRef.current.blur();
        toast({
          icon: '⚠️',
          title: '일시적으로 오류가 발생하였습니다.\n오류가 반복되면 1:1 문의를 남겨주세요.',
          variant: 'dark',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (isActive) {
      const newMessage: Message = { id: Date.now(), text: input, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput('');
      getProductName();
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedInput = e.target.value.replace(/\s{2,}/g, ' ');
    setInput(cleanedInput);
  };

  // 인풋 텍스트 입력시, input height 값 계산
  const adjustHeight = (element: HTMLTextAreaElement) => {
    element.style.height = '42px'; // 텍스트 제거시 초기화 값 전달
    const newHeight = Math.min(element.scrollHeight, 108); // 최대 높이
    element.style.height = `${newHeight}px`;
    setTextareaHeight(newHeight);

    // input 값 입력에 따른 스크롤 영역 height 값 수정
    if (window.visualViewport && scrollDivRef.current) {
      const currentVisualViewport = window.visualViewport.height;

      scrollDivRef.current.style.height = `${currentVisualViewport - newHeight - 22}px`;

      window.visualViewport.onscroll = () => {
        window.scrollTo(0, 0);
      };
    }
  };

  useEffect(() => {
    inputRef.current && adjustHeight(inputRef.current);
  }, [input]);

  // 모바일 키보드 활성화에 따른 스크롤 영역 height 값 계산
  useEffect(() => {
    function handleVisualViewportResize() {
      if (window.visualViewport && scrollDivRef.current) {
        const currentVisualViewport = window.visualViewport.height;

        scrollDivRef.current.style.height = `${currentVisualViewport - textareaHeight - 22}px`;

        window.visualViewport.onscroll = () => {
          window.scrollTo(0, 0);
        };
      }
    }

    if (window.visualViewport) {
      window.visualViewport.onresize = handleVisualViewportResize;
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleVisualViewportResize);
      }
    };
  }, [textareaHeight]); // 외부 값이 변할 때 내부 로직이 업데이트되어야 하기 때문에 종속성 전달

  // 메시지 추가 시
  useEffect(() => {
    // 로컬 스토리지에 저장
    setStoredMessages(messages);

    // 스크롤을 최하단으로 이동
    const isUserMessage = messages[messages.length - 1]?.sender === 'user';
    const scrollBehavior = isUserMessage ? 'instant' : 'smooth';
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: scrollBehavior });
    }
  }, [messages]);

  useEffect(() => {
    if (isLoading && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading]);

  return (
    <div className="flex h-screen flex-col bg-white">
      <div
        className="overflow-auto border-b-[1px] border-gray-150 pb-[10px] pl-[16px] pr-[12px] pt-[16px]"
        ref={scrollDivRef}
      >
        {messages.map((message) => (
          <BubbleContainer
            key={message.id}
            data={message}
            setMessages={setMessages}
            setIsLoading={setIsLoading}
            productNameList={productNameListData}
            isLatest={message.sender === 'bot' && message.id === latestBotMessageId}
          />
        ))}
        <div ref={messagesEndRef} />
        {isLoading && <BotBubble type="loading" />}
      </div>

      <div className="flex py-[10px] pl-[16px] pr-[12px]">
        <Input
          textarea={true}
          ref={inputRef}
          className="focous:border-none overflow-y-auto rounded-[8px] border-none bg-gray-100 py-[10px] pr-[70px] Body6M14 scrollbar-hide"
          value={input}
          onChange={handleInput}
          placeholder="최적화할 상품명을 입력해 주세요"
          InputProps={{
            endAdornment: (
              <button
                onClick={handleSendMessage}
                className={cn(
                  'rounded-[8px] bg-primary-500 px-[8px] py-[5px] text-white Caption1M12',
                  !isActive && 'bg-gray-300',
                )}
              >
                만들기
              </button>
            ),
            endAdornmentStyle: 'right-[8px] absolute m-auto whitespace-nowrap bottom-[7px]',
          }}
          maxLength={100}
        />
      </div>
    </div>
  );
};

export default MakeNamePage;
