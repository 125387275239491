import { cn } from '@/utils/tailwind';
import { getColor, getPercent } from '../_utils';

interface SalesItem {
  SalesAmount: number;
  MarketName: string;
}

interface ColorDistributionBarProps<T extends SalesItem> {
  salesItemList: Array<T>;
  activedMonthlySalesAmount: number;
}

const ColorDistributionBar = <T extends SalesItem>({
  salesItemList,
  activedMonthlySalesAmount,
}: ColorDistributionBarProps<T>) => {
  const salesWithPercent = salesItemList.map((item) => ({
    ...item,
    percentValue: getPercent(item.SalesAmount, activedMonthlySalesAmount),
  }));

  return (
    <div className="mb-[24px] mt-[36px] flex h-[40px] w-full items-center overflow-hidden rounded-[10px] border-[1px] border-gray-200">
      {salesWithPercent.map((item, index) => (
        <div
          key={`${item.MarketName} ${item.SalesAmount} ${index}`}
          className={cn('h-full', getColor(index).graph)}
          style={{
            width: `${item.percentValue}%`,
            transition: 'none',
          }}
        ></div>
      ))}
    </div>
  );
};

export default ColorDistributionBar;
