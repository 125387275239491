import ArrowGrayLeftMore from '@/assets/icons/V3/arrow/ArrowGrayLeftMore.svg?react';
import ArrowGrayRightMore from '@/assets/icons/V3/arrow/ArrowGrayRightMore.svg?react';
import IconBannerContainer from '@/containers/V3/Banner/IconBannerContainer';
import { formatDateToKST, formatKSTtoYearMonth } from '@/utils/parser';
import ReportAccordionContainer from '../StatisticsReport/_containers/ReportAccordionContainer';
import { getActivePeriod } from '../_utils';
import { calendarDailyListExample, salesInfoExample, settleInfoExample } from './_const';
import { StatisticsCalendar } from '@/components/V3/Calendar/StatisticsCalendar';
import useWebview from '@/hooks/V3/useWebview';

const StatisticsReportContainerExample = () => {
  const activedDate = formatDateToKST(new Date());
  const activeStartDate = new Date(getActivePeriod(activedDate, 1, true).dateFrom);
  const { postMessage } = useWebview();

  return (
    <>
      <div className="relative flex flex-grow flex-col overflow-y-auto px-[20px] scrollbar-hide">
        <div className="sticky top-0 flex items-center gap-[8px] bg-white pb-[6px] pt-[24px] z-1">
          <span>
            <ArrowGrayLeftMore />
          </span>
          <div className="text-gray-800 Title3S18">{formatKSTtoYearMonth(activedDate)}</div>
          <span>
            <ArrowGrayRightMore />
          </span>
        </div>

        <ReportAccordionContainer
          title="매출"
          data={salesInfoExample}
          className="pointer-events-none"
        />
        <div className="h-[1px] w-full flex-none bg-gray-150"></div>
        <ReportAccordionContainer
          title="정산"
          data={settleInfoExample}
          className="pointer-events-none"
        />
        <IconBannerContainer
          title="매출 데이터 받아보기"
          description="이메일을 등록하면 엑셀 파일을 발송해 드려요"
          className="mb-[24px] mt-[4px]"
          onClick={() => {}}
        />
        <div className="mb-[10px]">
          <div className="flex items-center gap-[12px]">
            <div className="flex items-center gap-[4px]">
              <div className="h-[8px] w-[8px] rounded-[4px] bg-primary-500"></div>
              <span className="text-gray-750 Caption1M12">매출</span>
            </div>
            <div className="flex items-center gap-[4px]">
              <div className="h-[8px] w-[8px] rounded-[4px] bg-gray-600"></div>
              <span className="text-gray-750 Caption1M12">정산</span>
            </div>
          </div>
        </div>
        <StatisticsCalendar
          activeStartDate={activeStartDate}
          calendarDailyList={calendarDailyListExample}
          onClickDay={() => {}}
        />
      </div>
      <div
        className="absolute bottom-0 left-[20px] right-[20px] pb-[24px] pt-[102px]"
        style={{
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
        }}
      >
        <IconBannerContainer
          title={'마켓을 연동해 보세요'}
          description={'통계 데이터를 확인할 수 있어요'}
          onClick={() =>
            postMessage({
              action: 'navigateApp',
              data: {
                key: 'MarketInfo',
                name: 'MarketInfo',
              },
            })
          }
        />
      </div>
    </>
  );
};

export default StatisticsReportContainerExample;
