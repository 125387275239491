import { CPLAT_API_ROUTES, usePost } from '@/api';
import { Button } from '@/components/V3/Button';
import { useToast } from '@/hooks/V3/useToast';
import useWebview from '@/hooks/V3/useWebview';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { setUser } from '@/redux/userSlice';
import { RegisteredEmailModalContainerProps } from '@/types/statistics';

const RegisteredEmailModalContainer = ({ onClose }: RegisteredEmailModalContainerProps) => {
  const user = useAppSelector((state) => state.user);
  const { postMessage } = useWebview();
  const dispatch = useAppDispatch();
  const { showToast } = useToast();

  const { mutate: setUserInfo } = usePost(CPLAT_API_ROUTES.setUserInfo);
  const { mutate: getUserInfo } = usePost(CPLAT_API_ROUTES.getUserInfo);

  const handleGetUserInfo = () => {
    getUserInfo(
      {
        cplatToken: user.CplatToken,
      },
      {
        onSuccess(res) {
          const { data, code } = res.data as CplatApiResponse<UserData[]>;
          if (code === '200') {
            dispatch(setUser(data[0]));
          }
        },
        onError(error) {
          showToast('warning', error.message);
          console.error(error);
        },
      },
    );
  };

  const handleSetUserInfo = () => {
    setUserInfo(
      {
        ...user,
        AdAgreeYn: 'Y',
      },
      {
        onSuccess(res) {
          const { code } = res.data as CplatApiResponse<UserData[]>;
          if (code === '200') {
            showToast('success', '동의가 완료되었어요');
            onClose();
            handleGetUserInfo();
            // 동의 완료 후 app에 알려주기
            postMessage({
              action: 'callApi',
              data: 'getUserInfo',
            });
          } else {
            showToast('warning', '다시 한번 시도해주세요');
          }
        },
      },
    );
  };

  const formatDate = (date: Date) => {
    const year = String(date.getFullYear()).slice(2);
    const month = String(date.getMonth() + 1);
    const day = String(date.getDate());
    return `${year}.${month}.${day}`;
  };

  const getDataDateRange = () => {
    const today = new Date();
    const month = today.getMonth();
    const startDate = new Date(today.getFullYear(), month, 1);
    const endDate = new Date(today.getFullYear(), month + 1, 0);

    const startFormatted = formatDate(startDate);
    const endFormatted = formatDate(endDate);

    return `${startFormatted}~${endFormatted.slice(-4)}`;
  };

  const getTransferDate = () => {
    const today = new Date();
    const month = today.getMonth();
    const transferDate = new Date(today.getFullYear(), month + 1, 10);
    const formattedDate = formatDate(transferDate);

    return `${formattedDate} 오전 10시`;
  };

  return (
    <div>
      {user.AdAgreeYn === 'Y' ? (
        <>
          <div className="text-gray-800 Title3S18">정보를 확인해 주세요</div>
          <ul className="mt-[4px] list-disc pl-[20px] text-v3-gray-750 Caption1M12">
            <li>등록한 이메일로 매출 데이터 파일이 전송돼요.</li>
            <li>이메일 변경이 필요한 경우 1:1 문의를 남겨주세요.</li>
          </ul>
        </>
      ) : (
        <>
          <div className="text-gray-800 Title3S18">매출 데이터를 받으려면 동의가 필요해요</div>
          <ul className="mt-[4px] list-disc pl-[20px] text-v3-gray-750 Caption1M12">
            <li className="whitespace-pre">{`현재 마케팅 정보 수신 설정이 꺼져있어\n매출 데이터를 이메일로 받아볼 수 없어요.`}</li>
          </ul>
        </>
      )}

      <div>
        <div className="flex items-center justify-between py-[16px]">
          <span className="text-v3-gray-600 Body6M14">이메일 주소</span>
          <span className="text-gray-800 Body1S16">{user.EmailAddreess}</span>
        </div>
        <div className="h-[1px] w-full flex-none bg-gray-150"></div>
        <div className="flex items-center justify-between py-[16px]">
          <span className="text-v3-gray-600 Body6M14">데이터 기간</span>
          <span className="text-gray-800 Body1S16">{getDataDateRange()}</span>
        </div>
        <div className="h-[1px] w-full flex-none bg-gray-150"></div>
        <div className="flex items-center justify-between py-[16px]">
          <span className="text-v3-gray-600 Body6M14">발송 예정일</span>
          <span className="text-gray-800 Body1S16">{getTransferDate()}</span>
        </div>
      </div>
      {user.AdAgreeYn === 'Y' ? (
        <div className="flex gap-[9px]">
          <Button
            variant={'SolidLow'}
            height={'52'}
            width={'full'}
            onClick={() =>
              postMessage({
                action: 'navigateApp',
                data: {
                  key: 'Inquire',
                  name: 'Inquire',
                },
              })
            }
          >
            1:1 문의
          </Button>
          <Button variant={'SolidHigh'} height={'52'} width={'full'} onClick={onClose}>
            확인했어요
          </Button>
        </div>
      ) : (
        <div className="flex gap-[9px]">
          <Button variant={'SolidLow'} height={'52'} width={'full'} onClick={onClose}>
            닫기
          </Button>
          <Button
            variant={'SolidHigh'}
            height={'52'}
            width={'full'}
            onClick={() => {
              handleSetUserInfo();
            }}
          >
            동의하고 데이터 받기
          </Button>
        </div>
      )}
    </div>
  );
};

export default RegisteredEmailModalContainer;
