import { activedMonthlySalesAmountExample, salesStoreListExample } from '../../_components/_const';
import PeriodStoreListContainer from './PeriodStoreListContainer';

const ExampleStoreListContainer = () => {
  return (
    <>
      <div className="flex flex-grow flex-col overflow-y-auto scrollbar-hide">
        <div>
          <PeriodStoreListContainer
            activedMonthlySalesAmount={activedMonthlySalesAmountExample}
            salesStoreList={salesStoreListExample}
          />
        </div>
      </div>
      <div
        className="absolute bottom-0 left-[20px] right-[20px] pb-[24px] pt-[102px]"
        style={{
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
        }}
      >
        <div className="flex w-full justify-center text-v3-gray-750 Body1S16">
          판매가 발생하면 분석 결과가 업데이트 돼요
        </div>
      </div>
    </>
  );
};

export default ExampleStoreListContainer;
