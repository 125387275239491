import { PeriodStoreListContainerProps, SalesStore } from '@/types/statistics';
import { cn } from '@/utils/tailwind';
import { getColor, getPercent } from '../../_utils';
import Badge from '@/components/V3/Badge';
import { autoComma } from '@/utils/parser';
import ColorDistributionBar from '../../_components/ColorDistributionBar';

const PeriodStoreListContainer = ({
  activedMonthlySalesAmount,
  salesStoreList,
}: PeriodStoreListContainerProps) => {
  return (
    <div className="flex flex-col">
      <ColorDistributionBar
        salesItemList={salesStoreList}
        activedMonthlySalesAmount={activedMonthlySalesAmount}
      />
      <div className="flex flex-col gap-[20px] pb-[20px]">
        {salesStoreList.map((item: SalesStore, index) => {
          const { background, border } = getColor(index);
          const percentValue = getPercent(item.SalesAmount, activedMonthlySalesAmount);
          return (
            <div
              className="flex items-center justify-between"
              key={`${item.StoreName} ${item.SalesAmount} ${index}`}
            >
              <div className="flex items-center gap-[10px]">
                <div
                  className={cn('h-[10px] w-[10px] rounded-[5px] border-[1px]', background, border)}
                ></div>
                <div className="flex items-center gap-[4px]">
                  <div className="line-clamp-2 max-w-[95px] text-gray-800 Body6M14">
                    {item.StoreName}
                  </div>
                  <Badge variant={'gray'} height={'26'} width={'6'}>
                    {item.MarketName}
                  </Badge>
                </div>
              </div>
              <div className="text-v3-gray-800 Body6M14">{`${autoComma(
                item.SalesAmount,
              )}원 (${percentValue}%)`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PeriodStoreListContainer;
