import { createBrowserRouter } from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';

import SignInPage from '@/pages/SignInPage';
import ErrorPage from '@/pages/ErrorPage';
import Root from '@/Root';
import Rootlayout from '@/components/layouts/Rootlayout';
import InquiryPage from '@/pages/InquiryPage';
import SignUpPage from '@/pages/SignUpPage';
import MarketPage from '@/pages/MarketPage';
import MyProfilePage from '@/pages/MyProfilePage';
import InquiryHistoryPage from '@/pages/InquiryHistoryPage';
import MarketRegisterPage from '@/pages/MarketRegisterPage';
import ProtectedRoute from './ProtectedRoute';
import ChangeMarketPasswordPage from '@/pages/ChangeMarketPasswordPage';
import ReqAddMarketPage from '@/pages/ReqAddMarketPage';
import FunctionGuidePage from '@/pages/FunctionGuidePage';
import AdminPage from '@/pages/AdminPage';
import ProductShipmentGuidePage from '@/pages/ProductShipmentGuidePage';
import ProductShipmentPage from '@/pages/ProductShipmentPage';
import MakeNamePage from '@/pages/Lab/MakeNamePage';
import DiagnoseProductPage from '@/pages/Lab/DiagnoseProductPage';
import ChangeNamePage from '@/pages/Lab/ChangeNamePage';
import MembershipPage from '@/pages/MembershipPage';
import YearEndPage from '@/pages/YearEndPage';
import InquiryListPage from '@/pages/Inquiry/InquiryListPage';
import InquiryDetailPage from '@/pages/Inquiry/InquiryDetailPage';
import TemplatesInquiryList from '@/pages/Inquiry/TemplatesInquiryList';
import TemplatesInquiryDetail from '@/pages/Inquiry/TemplatesInquiryDetail';
import InquiryGuidePage from '@/pages/Inquiry/InquiryGuidePage';
import DiscoverMain from '@/pages/V3/Discover/DiscoverMain';
import HighAndLowStart from '@/pages/V3/Discover/HighAndLow/HighAndLowStart';
import HighAndLowGame from '@/pages/V3/Discover/HighAndLow/HighAndLowGame';
import HighAndLowResult from '@/pages/V3/Discover/HighAndLow/HighAndLowResult';
import HighAndLowCategoryList from '@/pages/V3/Discover/HighAndLow/HighAndLowCategoryList';
import HighAndLowRanking from '@/pages/V3/Discover/HighAndLow/HighAndLowRanking';
import SearchProduct from '@/pages/V3/Discover/SearchProduct';
import AIChatBot from '@/pages/Lab/AIChatBot';
import StatisticsMain from '@/pages/V3/Statistics/StatisticsMain';

export const ROUTES = {
  root: '/',
  signin: '/signin',
  changePassword: '/market/register/changePassword',
  signup: '/signup',
  market: '/market',
  marketRegister: '/market/register',
  reqAddMarket: '/market/add',
  myProfile: '/my/profile',
  inquiry: '/my/inquiry',
  inquiryHistory: '/my/inquiry/history',
  error: '/error',
  productShipment: '/productShipment',
  productShipmentGuide: '/productShipment/productShipmentGuide',
  functionGuide: '/market/functionGuide',
  admin: '/admin',
  labMain: '/labMain',
  makeName: '/makeName',
  changeName: '/changeName',
  diagnoseProduct: '/diagnoseProduct',
  membership: '/membership',
  yearEnd: '/yearEnd',
  inquiryList: '/inquiryList',
  inquiryDetail: '/inquiryDetail',
  inquiryGuide: '/inquiryGuide',
  templatesInquiryList: '/templatesInquiryList',
  templatesInquiryDetail: '/templatesInquiryDetail',
  /** V3 */
  // 발견
  discoverMain: '/discoverMain',
  searchProduct: '/searchProduct',
  aiChatBot: '/aiChatBot',
  highAndLowStart: '/highAndLowStart',
  highAndLowCategoryList: '/highAndLowCategoryList',
  highAndLowGame: '/highAndLowGame',
  highAndLowResult: '/highAndLowResult',
  highAndLowRanking: '/highAndLowRanking',
  // 통계
  statisticsMain: '/statisticsMain',
};

// sentry를 통한 라우팅 오류 및 성능 추적
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    Component: Root,
    path: ROUTES.root,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Rootlayout bgColor="bg-white" />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              { path: ROUTES.inquiryHistory, element: <InquiryHistoryPage /> },
              {
                path: ROUTES.inquiry,
                element: <InquiryPage />,
              },
            ],
          },
        ],
      },
      {
        element: <Rootlayout />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              {
                path: ROUTES.signup,
                element: <SignUpPage />,
              },
              {
                path: ROUTES.market,
                index: true,
                element: <MarketPage />,
              },
              {
                path: ROUTES.reqAddMarket,
                element: <ReqAddMarketPage />,
              },
              {
                path: `${ROUTES.marketRegister}/:marketName`,
                element: <MarketRegisterPage />,
              },
              {
                path: `${ROUTES.changePassword}/:marketName`,
                element: <ChangeMarketPasswordPage />,
              },
              {
                path: ROUTES.myProfile,
                element: <MyProfilePage />,
              },
              {
                path: ROUTES.productShipment,
                element: <ProductShipmentPage />,
              },
              {
                path: ROUTES.productShipmentGuide,
                element: <ProductShipmentGuidePage />,
              },
              {
                path: ROUTES.functionGuide,
                element: <FunctionGuidePage />,
              },
              {
                path: ROUTES.admin,
                element: <AdminPage />,
              },
              {
                path: ROUTES.membership,
                element: <MembershipPage />,
              },
              {
                path: ROUTES.inquiryList,
                element: <InquiryListPage />,
              },
              {
                path: ROUTES.inquiryDetail,
                element: <InquiryDetailPage />,
              },
              {
                path: ROUTES.inquiryGuide,
                element: <InquiryGuidePage />,
              },
              {
                path: ROUTES.templatesInquiryList,
                element: <TemplatesInquiryList />,
              },
              {
                path: ROUTES.templatesInquiryDetail,
                element: <TemplatesInquiryDetail />,
              },
              /** V3 */
              // 발견
              {
                path: ROUTES.discoverMain,
                element: <DiscoverMain />,
              },
              {
                path: ROUTES.searchProduct,
                element: <SearchProduct />,
              },
              {
                path: ROUTES.aiChatBot,
                element: <AIChatBot />,
              },
              {
                path: ROUTES.highAndLowStart,
                element: <HighAndLowStart />,
              },
              {
                path: ROUTES.highAndLowCategoryList,
                element: <HighAndLowCategoryList />,
              },
              {
                path: ROUTES.highAndLowGame,
                element: <HighAndLowGame />,
              },
              {
                path: ROUTES.highAndLowResult,
                element: <HighAndLowResult />,
              },
              {
                path: ROUTES.highAndLowRanking,
                element: <HighAndLowRanking />,
              },
              // 통계
              {
                path: ROUTES.statisticsMain,
                element: <StatisticsMain />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.signin,
        element: <SignInPage />,
      },
      { path: ROUTES.error, element: <ErrorPage /> },
      { path: ROUTES.makeName, element: <MakeNamePage /> },
      { path: ROUTES.changeName, element: <ChangeNamePage /> },
      { path: ROUTES.diagnoseProduct, element: <DiagnoseProductPage /> },
    ],
  },
  {
    Component: YearEndPage,
    path: ROUTES.yearEnd,
    errorElement: <ErrorPage />,
  },
]);
