export interface ProcessorSales {
  processor: string;
  salesAmount: number;
  salesCount: number;
}

export interface NotRegisteredEmailModalContainerProps {
  onClose: () => void;
}

export interface RegisteredEmailModalContainerProps {
  onClose: () => void;
}

export interface SalesInfo {
  TotalSalesAmount: number;
  TotalSalesCount: number;
  TotalSalesDiscountAmount: number;
  TotalSalesShippingFee: number;
}

export const SalesInfoInitial: SalesInfo = {
  TotalSalesAmount: 0,
  TotalSalesCount: 0,
  TotalSalesDiscountAmount: 0,
  TotalSalesShippingFee: 0,
};

export interface SettleInfo {
  TotalSettleAmount: number;
  TotalSettleCompletionAmount: number;
}

export const SettleInfoInitial: SettleInfo = {
  TotalSettleAmount: 0,
  TotalSettleCompletionAmount: 0,
};

export interface CalendarDaily {
  Date: string;
  SalesAmount: number;
  SalesCount: number;
  SalesDiscountAmount: number;
  SalesShippingFee: number;
  SettleCompletionAmount: number;
  SettleYetAmount: number;
}

export interface StatisticsCalendarProps {
  /** 현재 활성화된 달의 첫째날 */
  activeStartDate: Date;
  /** 일별 데이터 리스트 */
  calendarDailyList: Array<CalendarDaily>;
  /** 각 일별 클릭 이벤트 */
  onClickDay: (date: Date) => void;
  className?: string;
}

export interface CalendarDetailModalContainerProps {
  selectedDetailDay: Date;
}

export interface Market {
  IsConnectedOff: boolean;
  MarketIconUrl: string;
  MarketName: string;
  SalesAmount: number;
  SalesCount: number;
  SalesDiscountAmount: number;
  SalesShippingFee: number;
  SettleAmount: number;
  StoreName: string;
}

export interface MonthlySales {
  FirstDayOfMonth: string;
  Month: string;
  SalesAmount: number;
  SalesAmountUntilDay: number;
}

export const MonthlySalesInitial: MonthlySales = {
  FirstDayOfMonth: '',
  Month: '',
  SalesAmount: 0,
  SalesAmountUntilDay: 0,
};

export interface SalesMarket {
  MarketIconUrl: string;
  MarketName: string;
  SalesAmount: number;
  SalesCount: number;
}
export interface SalesProduct {
  MarketIconUrl: string;
  MarketName: string;
  ProductName: string;
  ProductOptionName: string;
  SalesAmount: number;
  SalesCount: number;
  StoreName: string;
}
export interface SalesStore {
  MarketIconUrl: string;
  MarketName: string;
  SalesAmount: number;
  SalesCount: number;
  StoreName: string;
}

export type PeriodTabType = '스토어별' | '마켓별' | '상품별';

export type StatisticsTabType = '리포트' | '기간별';

export interface ColorScheme {
  border: string;
  background: string;
  graph: string;
}

export interface PeriodStoreListContainerProps {
  activedMonthlySalesAmount: number;
  salesStoreList: Array<SalesStore>;
}

export interface PeriodMarketListContainerProps {
  activedMonthlySalesAmount: number;
  salesMarketList: Array<SalesMarket>;
}

export interface PeriodProductListContainerProps {
  activedMonthlySalesAmount: number;
  salesProductList: Array<SalesProduct>;
}
