import { forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '@/utils/tailwind';

export const inputVariants = cva(
  'flex w-full text-gray-900 focus-visible:outline-none placeholder:text-gray-400',
  {
    variants: {
      variant: {
        default: 'bg-gray-100 py-[16px] pl-[16px] rounded-[10px] Body6M14',
        underline: 'py-[13px] border-b-[1px] border-v3-gray-600 rounded-[0px] Body5S14',
      },
    },
  },
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> &
  VariantProps<typeof inputVariants> & {
    textarea?: boolean;
    /** 인풋 내부 우측에 들어갈 컨텐츠 */
    InputProps?: {
      endAdornment?: React.ReactNode;
      endAdornmentStyle?: string;
    };
    Error?: {
      isError?: boolean;
      errorMessage?: string;
    };
  };

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (
    { className, textarea = false, variant = 'default', type, InputProps, Error, ...props },
    ref,
  ) => {
    return (
      <div>
        <div className="relative w-full">
          {textarea ? (
            <textarea
              className={cn(inputVariants({ variant, className }))}
              ref={ref as React.Ref<HTMLTextAreaElement>}
              {...props}
            />
          ) : (
            <input
              type={type}
              className={cn(inputVariants({ variant, className }))}
              ref={ref as React.Ref<HTMLInputElement>}
              {...props}
            />
          )}
          {InputProps?.endAdornment && (
            <div
              className={cn(
                'absolute right-0 top-1/2 m-auto -translate-y-1/2 transform whitespace-nowrap',
                InputProps?.endAdornmentStyle,
              )}
            >
              {InputProps.endAdornment}
            </div>
          )}
        </div>
        {Error?.isError && (
          <div className="mt-[6px] text-[#FF0000B2] Caption1M12">{Error?.errorMessage}</div>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
