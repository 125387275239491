import { CPLAT_API_ROUTES, usePost } from '@/api';
import BadgeSquare from '@/components/atoms/BadgeSquare';
import { getElapsedTime } from '@/containers/inquiry/InquiryListContainer';
import { useAppSelector } from '@/redux/store';
import { Inquiry, InquiryCommentTemplate, InquiryInitial } from '@/types/inquiry';
import { formatDate } from '@/utils/parser';
import { useEffect, useRef, useState } from 'react';
import { cn } from '@/utils/tailwind';
import Input from '@/components/atoms/Input';
import WarningTriangleRedIcon from '@/assets/icons/WarningTriangleRedIcon.svg?react';
import EmptyBoxIcon from '@/assets/icons/EmptyBoxIcon.svg?react';
import CloseBlackIcon from '@/assets/icons/CloseBlackIcon.svg?react';
import AIRobotIcon from '@/assets/icons/AIRobotIcon.svg?react';
import TemplateIcon from '@/assets/icons/TemplateIcon.svg?react';
import MorePlusGrayIcon from '@/assets/icons/MorePlusGrayIcon.svg?react';
import MorePlusOrangeIcon from '@/assets/icons/MorePlusOrangeIcon.svg?react';
import GlobalSpinner from '@/components/organisms/GlobalSpinner';
import EmptyFaceIcon from '@/assets/icons/EmptyFaceIcon.svg?react';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import Checkbox from '@/components/atoms/Checkbox';
import { useLocalStorage } from 'usehooks-ts';
import usePostMessage from '@/hooks/usePostMessage';
import Button from '@/components/atoms/Button';

type ActiveStatus = 'menu' | 'template' | 'keyboard';

const InquiryDetailPage = () => {
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const user = useAppSelector((state) => state.user);
  const { marketId, storeId, inquiryNo } = useAppSelector((state) => state.inquiry);
  const [inquiry, setInquiry] = useState<Inquiry>(InquiryInitial);
  const { elapsedTimeText, elapsedTimeColor } = getElapsedTime(inquiry.InquiryDate);
  const [activeStatus, setActiveStatus] = useState<ActiveStatus>('menu');
  const [input, setInput] = useState<string>('');
  const [enableSubmit, setEnableSubmit] = useState<boolean>(false);
  const [textareaHeight, setTextareaHeight] = useState<number>(64 - 22);
  const [inquiryCommentTemplateList, setInquiryCommentTemplateList] = useState<
    Array<InquiryCommentTemplate>
  >([]);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
  const [isInquirySubmitNeverAsk, setIsInquirySubmitNeverAsk] = useLocalStorage(
    'isInquirySubmitNeverAsk',
    false,
  );
  const { goBackApp, setRecoilData, openBottomSheet, navigateApp } = usePostMessage();

  const { mutate: getInquiryDetail, isPending: isPendingGetInquiryDetail } = usePost(
    CPLAT_API_ROUTES.getInquiryDetail,
  );
  const { mutate: generateAIRecommendedAnswer, isPending: isPendingGenerateAIRecommendedAnswer } =
    usePost(CPLAT_API_ROUTES.generateAIRecommendedAnswer);
  const { mutate: getInquiryCommentTemplate } = usePost(CPLAT_API_ROUTES.getInquiryCommentTemplate);
  const { mutate: registerInquiryComment, isPending: isPendingRegisterInquiryComment } = usePost(
    CPLAT_API_ROUTES.registerInquiryComment,
  );

  const handleRegisterInquiryComment = (CoupangWingId: string, inputData: string) => {
    registerInquiryComment(
      {
        cplatToken: user.CplatToken,
        MarketId: Number(marketId),
        StoreId: storeId,
        inquiryNo,
        ProductNo: inquiry.ProductNo,
        AnswerContent: inputData,
        QnaTypeCode: inquiry.QnaTypeCode,
        Cafe24Title: inquiry.Title,
        CoupangWingId,
        EsmToken: inquiry.EsmToken,
        LotteonProductItemNo: inquiry.ProductItemNo,
      },
      {
        onSuccess(res) {
          const { code, message } = res.data;
          if (code === '200') {
            if (CoupangWingId) {
              openBottomSheet('close');
            }
            goBackApp('Y');
          } else if (code === '765') {
            goBackApp('문의 내역을 다시 불러올게요');
          } else if (code === '766') {
            if (CoupangWingId) {
              openBottomSheet('error');
            } else {
              openBottomSheet('open');
            }
          } else {
            goBackApp(message);
            openBottomSheet('close');
          }
        },
        onSettled() {
          if (inquiry.CoupangWingId) {
            openBottomSheet('close');
          }
        },
      },
    );
  };

  const handleGetInquiryCommentTemplate = () => {
    getInquiryCommentTemplate(
      {
        cplatToken: user.CplatToken,
      },
      {
        onSuccess(res) {
          const code = res.data.code;
          if (code === '200') {
            const InquiryCommentTemplateList = res.data.data[0].InquiryCommentTemplateList;
            setInquiryCommentTemplateList(InquiryCommentTemplateList);
          } else {
            setInquiryCommentTemplateList([]);
          }
        },
      },
    );
  };

  const handleGenerateAIRecommendedAnswer = () => {
    generateAIRecommendedAnswer(
      {
        cplatToken: user.CplatToken,
        productName: inquiry.ProductName,
        inquiryContent: inquiry.Content,
        inquiryType: inquiry.InquiryType,
      },
      {
        onSuccess(res) {
          const { AiRecommendedAnswer } = res.data.data[0];
          setInput(AiRecommendedAnswer);
        },
      },
    );
  };

  const handleInquiryDetail = () => {
    getInquiryDetail(
      {
        cplatToken: user.CplatToken,
        MarketId: Number(marketId),
        StoreId: storeId,
        inquiryNo,
      },
      {
        onSuccess(res) {
          const inquiry = res.data.data[0].InquiryList[0];
          setInquiry(inquiry);
        },
      },
    );
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedInput = e.target.value.replace(/\s{2,}/g, ' ');
    setInput(cleanedInput);
  };

  const handleSubmit = () => {
    if (enableSubmit) {
      if (isInquirySubmitNeverAsk) {
        handleRegisterInquiryComment(inquiry.CoupangWingId, input);
      } else {
        setIsSubmitModalOpen(true);
      }
    } else {
      inputRef.current?.focus();
    }
  };

  // 인풋 텍스트 입력시, 텍스트 에어리아 height 값 계산 후 스크롤 영역 height 수정
  const adjustHeight = (element: HTMLTextAreaElement) => {
    element.style.height = '42px'; // 텍스트 제거시 초기화 값 전달
    const newHeight = Math.min(element.scrollHeight, 108); // 최대 높이
    element.style.height = `${newHeight}px`;
    setTextareaHeight(newHeight);

    if (window.visualViewport && scrollDivRef.current) {
      const currentVisualViewport = window.visualViewport.height;

      scrollDivRef.current.style.height = `${currentVisualViewport - newHeight - 22}px`;

      window.visualViewport.onscroll = () => {
        window.scrollTo(0, 0);
      };
    }
  };

  useEffect(() => {
    handleInquiryDetail();
  }, []);

  useEffect(() => {
    if (input.length > 0) {
      setRecoilData('disabledBack', 'Y');
    } else {
      setRecoilData('disabledBack', 'N');
    }
    if (input.length >= 10) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [input]);

  // 키보드 활성화/비활성화될 때, '키보드 영역 + 인풋 영역'을 제외한 스크롤 영역을 계산
  useEffect(() => {
    function handleVisualViewportResize() {
      if (window.visualViewport && scrollDivRef.current) {
        const currentVisualViewport = window.visualViewport.height;

        scrollDivRef.current.style.height = `${currentVisualViewport - textareaHeight - 22}px`;

        window.visualViewport.onscroll = () => {
          window.scrollTo(0, 0);
        };
      }
    }

    if (window.visualViewport) {
      window.visualViewport.onresize = handleVisualViewportResize;
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleVisualViewportResize);
      }
    };
  }, [textareaHeight]); // 외부 값이 변할 때 내부 로직이 업데이트되어야 하기 때문에 종속성 전달

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      if (data['coupangInput']) {
        let inputData: string = '';
        if (inputRef.current) {
          inputData = inputRef.current?.value;
        }
        handleRegisterInquiryComment(data['coupangInput'], inputData);
      }

      if (data['updateTemplateData']) {
        handleGetInquiryCommentTemplate();
      }
    };

    window.addEventListener('message', handleMessage);
    document.addEventListener('message', handleMessage as EventListener);
    return () => {
      window.removeEventListener('message', handleMessage);
      document.addEventListener('message', handleMessage as EventListener);
    };
  }, []);

  useEffect(() => {
    inputRef.current && adjustHeight(inputRef.current);
  }, [input]);

  if (!isPendingGetInquiryDetail) {
    return (
      <div className="flex h-screen flex-col bg-white">
        <div
          className={cn(
            'overflow-y-auto px-[20px] pb-[30px]',

            inquiry.CheckedYn === 'N' && 'h-screen',
          )}
          ref={scrollDivRef}
        >
          <div className="mt-[20px] text-gray-800 Body1S16">{inquiry.ProductName}</div>
          <div className="mb-[20px] mt-[16px] h-[1px] w-full bg-gray-150"></div>
          <div className="mb-[20px] flex items-center gap-[8px]">
            <div className="flex items-center gap-[6px]">
              <img src={inquiry.MarketIconUrl} alt="icon" className="h-[20px] w-[20px]" />
              <span className="text-gray-750 Body6M14">{inquiry.StoreName}</span>
            </div>
            <div className="h-[18px] w-[1px] bg-gray-150"></div>
            <span className="text-gray-750 Body6M14">{formatDate(inquiry.InquiryDate)}</span>
            <div className="h-[18px] w-[1px] bg-gray-150"></div>
            {inquiry.CheckedYn === 'N' ? (
              <BadgeSquare variant={elapsedTimeColor} size="md">
                <div className="flex items-center gap-[2px]">
                  {elapsedTimeColor === 'red' && <WarningTriangleRedIcon />}
                  {elapsedTimeText}
                </div>
              </BadgeSquare>
            ) : (
              <BadgeSquare variant={'gray'} size="md">
                처리완료
              </BadgeSquare>
            )}
          </div>
          <div className="text-gray-700 Body2M16">
            [{inquiry.QnaTypeCode}] {inquiry.Title}
          </div>
          <div className="text-gray-700 Body2M16">{inquiry.Content}</div>
          {Array.isArray(inquiry.AttachmentsList) && inquiry.AttachmentsList.length > 0 && (
            <div className="mt-[20px] flex items-center gap-[6px] overflow-x-auto scrollbar-hide">
              {inquiry.AttachmentsList.map((item) => (
                <img
                  src={item}
                  className="h-[160px] w-[160px] rounded-[4px] border-[1px] border-gray-150"
                />
              ))}
            </div>
          )}
        </div>
        <div>
          {/* 처리완료 */}
          {inquiry.CheckedYn === 'Y' ? (
            <div className="h-full">
              <div className="h-[12px] w-full bg-gray-150"></div>
              {/* 답변 O */}
              {inquiry.InquiryCommentList.length > 0 ? (
                <div className="px-[20px] pt-[30px]">
                  <div className="mb-[16px] text-gray-800 Body1S16">답변</div>
                  {inquiry.InquiryCommentList.length > 0 && (
                    <div className="pb-[60px] text-gray-700 Body2M16">
                      {inquiry.InquiryCommentList.map((item) => (
                        <div>{item.InquiryComment}</div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                // 답변 X
                <div className="flex h-full flex-col items-center justify-center gap-[20px] py-[60px]">
                  <EmptyBoxIcon />
                  <div className="text-gray-750 Body1S16">판매자 페이지에서 답변을 완료했어요</div>
                </div>
              )}
            </div>
          ) : (
            <div>
              {/* 신규문의 */}
              <div className="border-t-[1px] border-gray-200">
                <div className="flex w-screen gap-[8px] bg-white py-[11px] pl-[12px] pr-[16px]">
                  {activeStatus === 'menu' ? (
                    <span
                      className="mb-[10px] self-end"
                      onClick={() => {
                        inputRef.current?.focus();
                        setActiveStatus('keyboard');
                      }}
                    >
                      <CloseBlackIcon />
                    </span>
                  ) : (
                    <span
                      className="mb-[10px] self-end"
                      onClick={() => {
                        setActiveStatus('menu');
                      }}
                    >
                      <MorePlusGrayIcon />
                    </span>
                  )}

                  <Input
                    textarea={true}
                    ref={inputRef}
                    className="focous:border-none h-[42px] overflow-y-auto rounded-[8px] border-none bg-gray-100 py-[10px] pr-[64px] Body6M14 scrollbar-hide"
                    value={input}
                    onChange={handleInput}
                    placeholder="답변을 500자 이내로 작성해 주세요"
                    InputProps={{
                      endAdornment: (
                        <button
                          onClick={handleSubmit}
                          className={cn(
                            'w-[48px] rounded-[8px] px-[8px] py-[5px] text-white Caption1M12',
                            enableSubmit ? 'bg-primary-500' : 'bg-gray-300',
                          )}
                        >
                          등록
                        </button>
                      ),
                      endAdornmentStyle:
                        'right-[8px] absolute m-auto whitespace-nowrap bottom-[7px]',
                    }}
                    onFocus={() => {
                      setActiveStatus('keyboard');
                    }}
                    maxLength={500}
                  />
                </div>
                {activeStatus === 'menu' && (
                  <div className="flex h-[288px] gap-[28px] bg-gray-50 px-[34px] py-[40px]">
                    <div
                      className="flex flex-col items-center gap-[6px]"
                      onClick={() => {
                        handleGenerateAIRecommendedAnswer();
                      }}
                    >
                      <div className="relative h-[56px] w-[56px] rounded-[40px] bg-blue-400">
                        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                          <AIRobotIcon />
                        </span>
                      </div>
                      <div className="text-gray-750 Caption1M12">AI 답변 생성</div>
                    </div>
                    <div
                      className="flex flex-col items-center gap-[6px]"
                      onClick={() => {
                        handleGetInquiryCommentTemplate();
                        setActiveStatus('template');
                      }}
                    >
                      <div className="relative h-[56px] w-[56px] rounded-[40px] bg-gray-600">
                        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                          <TemplateIcon />
                        </span>
                      </div>
                      <div className="text-gray-750 Caption1M12">템플릿 선택</div>
                    </div>
                  </div>
                )}
                {activeStatus === 'template' && (
                  <div className="mt-[8px] w-full px-[20px]">
                    <div
                      className="flex items-center gap-[8px] py-[17px]"
                      onClick={() => {
                        navigateApp('TemplateTab', {
                          initialIndex: 1,
                        });
                      }}
                    >
                      <MorePlusOrangeIcon />
                      <span className="text-primary-500 Body3S15">템플릿 관리하기</span>
                    </div>
                    {inquiryCommentTemplateList.length > 0 ? (
                      <div className="h-[224px] overflow-y-auto scrollbar-hide">
                        {inquiryCommentTemplateList.map((item) => (
                          <div
                            className="py-[17px] text-gray-700 Body2M16"
                            key={item.TemplateNo}
                            onClick={() => setInput(item.Content)}
                          >
                            {item.Title}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex h-[227px] w-full flex-col items-center justify-center gap-[20px] text-gray-750 Body1S16">
                        <EmptyFaceIcon />
                        <div>등록된 템플릿이 없어요</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {(isPendingGenerateAIRecommendedAnswer || isPendingRegisterInquiryComment) && (
          <GlobalSpinner />
        )}
        <Dialog open={isSubmitModalOpen}>
          <DialogContent className="max-w-[315px] gap-0 p-[20px] pt-[30px]">
            <div className="mb-[30px] flex flex-col gap-[16px]">
              <div className="text-gray-900 Title3S18">답변을 등록할게요</div>
              <div className="whitespace-pre text-gray-750 Body6M14">{`등록 이후 수정 및 삭제가 필요할 경우\n마켓 판매자 페이지에서 진행해 주세요.`}</div>
              <div
                className="flex items-center gap-[8px]"
                onClick={() => {
                  setIsInquirySubmitNeverAsk(!isInquirySubmitNeverAsk);
                }}
              >
                <Checkbox checked={isInquirySubmitNeverAsk} />
                <span className="text-gray-800 Body8M14">다음부터 바로 등록하기</span>
              </div>
            </div>

            <div className="flex justify-center gap-[10px]">
              <Button
                type="button"
                variant={'secondary'}
                className="h-[44px] w-[130px]"
                onClick={() => setIsSubmitModalOpen(false)}
              >
                닫기
              </Button>
              <Button
                type="button"
                variant={'default'}
                className="h-[44px] w-[130px]"
                onClick={() => {
                  handleRegisterInquiryComment(inquiry.CoupangWingId, input);
                  setIsSubmitModalOpen(false);
                }}
              >
                등록하기
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
};

export default InquiryDetailPage;
