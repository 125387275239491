import AccessoriesCategoryIcon from '@/assets/icons/V3/category/AccessoriesCategoryIcon.svg?react';
import BabyCategoryIcon from '@/assets/icons/V3/category/BabyCategoryIcon.svg?react';
import AllCategoryIcon from '@/assets/icons/V3/category/AllCategoryIcon.svg?react';
import BeautyCategoryIcon from '@/assets/icons/V3/category/BeautyCategoryIcon.svg?react';
import ClothingCategoryIcon from '@/assets/icons/V3/category/ClothingCategoryIcon.svg?react';
import ElectronicsCategoryIcon from '@/assets/icons/V3/category/ElectronicsCategoryIcon.svg?react';
import FoodCategoryIcon from '@/assets/icons/V3/category/FoodCategoryIcon.svg?react';
import FurnitureCategoryIcon from '@/assets/icons/V3/category/FurnitureCategoryIcon.svg?react';
import HealthCategoryIcon from '@/assets/icons/V3/category/HealthCategoryIcon.svg?react';
import LeisureCategoryIcon from '@/assets/icons/V3/category/LeisureCategoryIcon.svg?react';
import SportsCategoryIcon from '@/assets/icons/V3/category/SportsCategoryIcon.svg?react';

export const CATEGORY_KEYWORD_LIST = [
  {
    title: '전체',
    icon: AllCategoryIcon,
  },

  {
    title: '패션의류',
    icon: ClothingCategoryIcon,
  },

  {
    title: '패션잡화',
    icon: AccessoriesCategoryIcon,
  },

  {
    title: '화장품/미용',
    icon: BeautyCategoryIcon,
  },

  {
    title: '디지털/가전',
    icon: ElectronicsCategoryIcon,
  },

  {
    title: '가구/인테리어',
    icon: FurnitureCategoryIcon,
  },

  {
    title: '출산/육아',
    icon: BabyCategoryIcon,
  },

  {
    title: '식품',
    icon: FoodCategoryIcon,
  },

  {
    title: '스포츠/레저',
    icon: SportsCategoryIcon,
  },

  {
    title: '생활/건강',
    icon: HealthCategoryIcon,
  },

  {
    title: '여가/생활편의',
    icon: LeisureCategoryIcon,
  },
];
