import Tab from '@/components/V3/Tab';
import { autoComma } from '@/utils/parser';
import { useState } from 'react';
import { PeriodTabType } from '@/types/statistics';
import ArrowRedUpIcon from '@/assets/icons/V3/arrow/ArrowRedUpIcon.svg?react';
import { cn } from '@/utils/tailwind';
import { BarChart } from '@/components/V3/Chart/BarChart';
import {
  activedMonthlySalesExample,
  monthlySalesListExample,
  salesStoreListExample,
  activedMonthlySalesAmountExample,
} from './_const';
import PeriodStoreListContainer from '../StatisticsPeriod/_containers/PeriodStoreListContainer';
import IconBannerContainer from '@/containers/V3/Banner/IconBannerContainer';
import useWebview from '@/hooks/V3/useWebview';

interface StatisticsPeriodContainerExampleProps {
  isRegisteredMarket: boolean;
  totalSalesAmount: number;
}

const StatisticsPeriodContainerExample = ({
  isRegisteredMarket,
  totalSalesAmount,
}: StatisticsPeriodContainerExampleProps) => {
  const [activedItem, setActivedItem] = useState<PeriodTabType>('스토어별');
  const { postMessage } = useWebview();

  return (
    <>
      <div className="flex flex-grow flex-col overflow-y-auto scrollbar-hide">
        <div className="flex flex-col gap-[4px] px-[20px] pt-[24px]">
          <div className="text-gray-800 Title3S18">
            {autoComma(activedMonthlySalesExample?.SalesAmount)}원
          </div>
          <div className={cn('flex items-center gap-[4px] text-red-400 Caption1M12')}>
            <span>지난달 같은 기간 보다</span>
            <div className="flex items-center">
              <ArrowRedUpIcon />

              <span>293,530</span>
            </div>
          </div>
        </div>
        <div className="px-[20px]">
          <BarChart
            monthlySalesList={monthlySalesListExample.slice(-(monthlySalesListExample.length - 1))} // 그래프에서 6개월치만 보여주기 위해 첫번째 값 제거
            onClickBar={() => {}}
            activedMonthlySales={activedMonthlySalesExample}
          />
        </div>
        <div className="mt-[24px] h-[12px] w-full flex-none bg-gray-100"></div>
        <div className="pointer-events-none sticky top-0 bg-white px-[20px] pt-[20px] z-1">
          <Tab
            variant={'button'}
            activedItem={activedItem}
            setActivedItem={setActivedItem}
            itemList={['스토어별', '마켓별', '상품별']}
          />
        </div>
        <div className="px-[20px]">
          <div>
            <PeriodStoreListContainer
              activedMonthlySalesAmount={activedMonthlySalesAmountExample}
              salesStoreList={salesStoreListExample}
            />
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-[20px] right-[20px] pb-[24px] pt-[102px]"
        style={{
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
        }}
      >
        {isRegisteredMarket && totalSalesAmount === 0 ? (
          <div className="flex w-full justify-center text-v3-gray-750 Body1S16">
            판매가 발생하면 분석 결과가 업데이트 돼요
          </div>
        ) : (
          <IconBannerContainer
            title={'마켓을 연동해 보세요'}
            description={'통계 데이터를 확인할 수 있어요'}
            onClick={() =>
              postMessage({
                action: 'navigateApp',
                data: {
                  key: 'MarketInfo',
                  name: 'MarketInfo',
                },
              })
            }
          />
        )}
      </div>
    </>
  );
};

export default StatisticsPeriodContainerExample;
