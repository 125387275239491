import { safeAreaInsetsState } from '@/store/atom';
import { useRecoilValue } from 'recoil';
import StatisticsReportContainer from './StatisticsReport/StatisticsReportContainer';
import StatisticsPeriodContainer from './StatisticsPeriod/StatisticsPeriodContainer';
import { useEffect, useState } from 'react';
import Tab from '@/components/V3/Tab';
import {
  CalendarDaily,
  MonthlySales,
  MonthlySalesInitial,
  SalesInfo,
  SalesInfoInitial,
  SalesMarket,
  SalesProduct,
  SalesStore,
  SettleInfo,
  SettleInfoInitial,
  StatisticsTabType,
} from '@/types/statistics';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { formatDateToKST } from '@/utils/parser';
import { getActivePeriod } from './_utils';
import { useAppSelector } from '@/redux/store';
import StatisticsReportContainerExample from './_components/StatisticsReportContainerExample';
import StatisticsPeriodContainerExample from './_components/StatisticsPeriodContainerExample';

const StatisticsMain = () => {
  const user = useAppSelector((state) => state.user);
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);
  const [activedItem, setActivedItem] = useState<StatisticsTabType>('리포트');
  const [isRegisteredMarket, setIsRegisteredMarket] = useState<boolean>(true);
  // 레포트 관련 변수
  const today = formatDateToKST(new Date());
  const [activedDate, setActivedDate] = useState<string>(today);
  const [salesInfo, setSalesInfo] = useState<SalesInfo>(SalesInfoInitial);
  const [settleInfo, setSettleInfo] = useState<SettleInfo>(SettleInfoInitial);
  const [calendarDailyList, setCalendarDailyList] = useState<Array<CalendarDaily>>([]);
  // 기간별 관련 변수
  const [totalSalesAmount, setTotalSalesAmount] = useState<number>(0);
  const [monthlySalesList, setMonthlySalesList] = useState<Array<MonthlySales>>([]);
  const [salesMarketList, setSalesMarketList] = useState<Array<SalesMarket>>([]);
  const [salesProductList, setSalesProductList] = useState<Array<SalesProduct>>([]);
  const [salesStoreList, setSalesStoreList] = useState<Array<SalesStore>>([]);
  const [activedMonthlySales, setActivedMonthlySales] = useState<MonthlySales>(MonthlySalesInitial);

  const { mutate: getCalendar } = usePost(CPLAT_API_ROUTES.getCalendar);
  const { mutate: getPeriodReport } = usePost(CPLAT_API_ROUTES.getPeriodReport);
  const { mutate: getPeriodDetail } = usePost(CPLAT_API_ROUTES.getPeriodDetail);
  const { mutate: getRegisteredMarketInfo } = usePost(CPLAT_API_ROUTES.getRegisteredMarketInfo);

  const handleGetRegisteredMarketInfo = () => {
    getRegisteredMarketInfo(
      {
        cplatToken: user.CplatToken,
      },
      {
        onSuccess(res) {
          const { code } = res.data as CplatApiResponse<UserData[]>;
          if (code === '200') {
            setIsRegisteredMarket(true);
          } else {
            setIsRegisteredMarket(false);
          }
        },
      },
    );
  };

  const handleGetCalendar = () => {
    const { dateFrom, dateTo } = getActivePeriod(activedDate, 1, false);
    getCalendar(
      {
        cplatToken: user.CplatToken,
        dateFrom,
        dateTo,
      },
      {
        onSuccess(res) {
          const { CalendarDailyList, SalesInfo, SettleInfo } = res.data.data[0];
          setSalesInfo(SalesInfo);
          setSettleInfo(SettleInfo);
          setCalendarDailyList(CalendarDailyList);
        },
      },
    );
  };

  const handleGetPeriodReport = () => {
    const { dateFrom, dateTo } = getActivePeriod(today, 7, true);
    getPeriodReport(
      {
        cplatToken: user.CplatToken,
        dateFrom,
        dateTo,
      },
      {
        onSuccess(res) {
          const { data, code } = res.data;
          if (code === '200') {
            const { TotalSalesAmount, MonthlySalesList } = data[0].SalesInfo;
            setTotalSalesAmount(TotalSalesAmount);
            setMonthlySalesList(MonthlySalesList);
            setActivedMonthlySales(MonthlySalesList[MonthlySalesList.length - 1]);
          } else {
          }
        },
      },
    );
  };

  const handleGetPeriodDetail = (month: string) => {
    const currentMonth = new Date().getMonth() + 1;
    const yearMonth = Number(month.split('-')[0]);
    const monthNumber = Number(month.split('-')[1]);
    const activedDate = new Date();
    activedDate.setFullYear(yearMonth);
    activedDate.setMonth(monthNumber - 1);
    const { dateFrom, dateTo } = getActivePeriod(
      formatDateToKST(activedDate),
      1,
      currentMonth === monthNumber,
    );
    getPeriodDetail(
      {
        cplatToken: user.CplatToken,
        dateFrom,
        dateTo,
      },
      {
        onSuccess(res) {
          const { data, code } = res.data;
          if (code === '200') {
            const { SalesMarketList, SalesProductList, SalesStoreList } = data[0];
            setSalesMarketList(SalesMarketList);
            setSalesProductList(SalesProductList);
            setSalesStoreList(SalesStoreList);
          } else {
            setSalesMarketList([]);
            setSalesProductList([]);
            setSalesStoreList([]);
          }
        },
      },
    );
  };

  useEffect(() => {
    handleGetCalendar();
  }, [activedDate]);

  useEffect(() => {
    if (activedMonthlySales.Month) {
      handleGetPeriodDetail(activedMonthlySales.Month);
    }
  }, [activedMonthlySales]);

  useEffect(() => {
    handleGetPeriodReport();
    handleGetRegisteredMarketInfo();
  }, []);

  return (
    <main
      className="relative flex h-screen select-none flex-col bg-white"
      style={{
        paddingTop: safeAreaInsets.topInset,
      }}
    >
      <div>
        <div className="mb-[12px] mt-[28px] px-[20px] text-v3-gray-900 TitleS28">통계</div>
        <Tab
          variant={'underline'}
          activedItem={activedItem}
          setActivedItem={setActivedItem}
          itemList={['리포트', '기간별']}
        />
      </div>
      {activedItem === '리포트' ? (
        isRegisteredMarket ? (
          <StatisticsReportContainer
            activedDate={activedDate}
            setActivedDate={setActivedDate}
            salesInfo={salesInfo}
            settleInfo={settleInfo}
            calendarDailyList={calendarDailyList}
          />
        ) : (
          <StatisticsReportContainerExample />
        )
      ) : isRegisteredMarket && totalSalesAmount > 0 ? (
        <StatisticsPeriodContainer
          monthlySalesList={monthlySalesList}
          salesMarketList={salesMarketList}
          salesProductList={salesProductList}
          salesStoreList={salesStoreList}
          activedMonthlySales={activedMonthlySales}
          setActivedMonthlySales={setActivedMonthlySales}
        />
      ) : (
        <StatisticsPeriodContainerExample
          isRegisteredMarket={isRegisteredMarket}
          totalSalesAmount={totalSalesAmount}
        />
      )}
    </main>
  );
};

export default StatisticsMain;
