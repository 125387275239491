import Input from '@/components/V3/Input';
import ImageBannerContainer from '@/containers/V3/Banner/ImageBannerContainer';
import useWebview from '@/hooks/V3/useWebview';
import IconBannerContainer from '@/containers/V3/Banner/IconBannerContainer';
import { Button } from '@/components/V3/Button';
import BlogItem from './_components/BlogItem';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useEffect, useState } from 'react';
import SearchIcon from '@/assets/icons/v3/SearchIcon.svg?react';
import { ApiStatusType, BlogPosting } from '@/types/discover';
import BlogErrorContainer from './_components/BlogErrorContainer';
import BlogItemSkeleton from './_components/BlogItemSkeleton';
import { useRecoilValue } from 'recoil';
import { safeAreaInsetsState } from '@/store/atom';
import { useAppSelector } from '@/redux/store';

const DiscoverMain = () => {
  const { postMessage } = useWebview();
  const [blogList, setBlogList] = useState<Array<BlogPosting>>([]);
  const [apiStatus, setApiStatus] = useState<ApiStatusType>('loading');
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);
  const user = useAppSelector((state) => state.user);
  const [blogLastNumber, setBlogLastNumber] = useState<number>(5);

  const { mutate: getBlogPosting } = usePost(CPLAT_API_ROUTES.getBlogPosting);

  const handleGetBlogPosting = () => {
    setApiStatus('loading');
    getBlogPosting(
      {},
      {
        onSuccess(res) {
          const { code, data } = res.data;
          if (code === '200') {
            const BlogPostingList = data[0].BlogPostingList;
            setBlogList(BlogPostingList);
            setApiStatus('success');
          } else {
            setApiStatus('error');
          }
        },
        onError() {
          setApiStatus('error');
        },
      },
    );
  };

  useEffect(() => {
    handleGetBlogPosting();
  }, []);

  return (
    <main
      className="flex select-none flex-col bg-white px-[20px]"
      style={{
        paddingTop: safeAreaInsets.topInset,
        paddingBottom: safeAreaInsets.bottomInset,
      }}
    >
      <div className="h-screen overflow-y-auto scrollbar-hide">
        <div className="text-v3-gray-900 mb-[20px] mt-[28px] TitleS28">발견</div>
        <Input
          variant={'underline'}
          placeholder="분석하고 싶은 키워드를 입력하세요"
          readOnly={true}
          onClick={() => {
            // FIXME: 추후에 재검토해서 사용하기
            // postMessage({
            //   action: 'navigateApp',
            //   data: {
            //     key: 'SearchProduct',
            //     name: 'SearchProduct',
            //   },
            // });
            postMessage({
              action: 'navigateApp',
              data: {
                key: 'KeywordSearchWebviewPage',
                name: 'KeywordSearchWebviewPage',
                params: {
                  // url: `http://10.133.63.239:3000/mobileKeywordAnalyze?cplatToken=${user.CplatToken}`,
                  // url: `https://sellkey-dev.sellerbox.io/mobileKeywordAnalyze?cplatToken=${user.CplatToken}`,
                  url: `https://sellkey.sellerbox.io/mobileKeywordAnalyze?cplatToken=${user.CplatToken}`,
                },
              },
            });
          }}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <ImageBannerContainer
          variant={'highAndLowGame'}
          title={'하이앤로우 게임'}
          description={'어떤 키워드의 검색량이 많을까요?'}
          onClick={() => {
            postMessage({
              action: 'navigateApp',
              data: {
                key: 'HighAndLowStart',
                name: 'HighAndLowStart',
              },
            });
          }}
          className="mb-[24px] mt-[20px]"
        />
        <div className="mb-[20px] flex items-center gap-[4px]">
          <span className="text-gray-800 Title3S18">Sellerbox</span>
          <span
            className="Title3S18"
            style={{
              background: 'linear-gradient(270deg, #78B0FF 0%, #E483FF 47%, #FF8585 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            AI
          </span>
        </div>
        <div className="flex flex-col gap-[10px]">
          <IconBannerContainer
            title={'상품명 제작'}
            description={'상위 노출시킬 수 있는 상품명을 만들어요'}
            onClick={() => {
              postMessage({
                action: 'navigateApp',
                data: {
                  key: 'MakeNameWebview',
                  name: 'MakeNameWebview',
                },
              });
            }}
          />
          <IconBannerContainer
            title={'상품 페이지 진단'}
            description={'상세 페이지의 개선점을 파악하고 최적화 해요'}
            onClick={() => {
              postMessage({
                action: 'navigateApp',
                data: {
                  key: 'DiagnoseProductWebview',
                  name: 'DiagnoseProductWebview',
                },
              });
            }}
          />
          <IconBannerContainer
            title={'도매몰 상품명 바꾸기'}
            description={'기존 상품명을 변경해 상품을 상위 노출 시켜요'}
            onClick={() => {
              postMessage({
                action: 'navigateApp',
                data: {
                  key: 'ChangeNameWebview',
                  name: 'ChangeNameWebview',
                },
              });
            }}
          />
          {/* 추후 사용 예정 */}
          {/* <IconBannerContainer
            title={'셀러박스 AI 챗봇'}
            description={'원하는 판매 데이터를 바로 알아보세요'}
            onClick={() => {
              postMessage({
                action: 'navigateApp',
                data: {
                  key: 'AIChatBot',
                  name: 'AIChatBot',
                },
              });
            }}
          /> */}
        </div>
        <div className="mb-[16px] mt-[40px]">
          <span className="text-gray-800 Title3S18">블로그</span>
        </div>
        {apiStatus === 'loading' ? (
          <div className="mb-[20px] flex w-full flex-col gap-[12px]">
            <BlogItemSkeleton />
            <BlogItemSkeleton />
          </div>
        ) : apiStatus === 'error' ? (
          <BlogErrorContainer onClick={handleGetBlogPosting} />
        ) : (
          <div className="pb-[80px]">
            <div className="mb-[20px] flex w-full flex-col gap-[12px]">
              {blogList.slice(0, 2).map((item) => (
                <BlogItem
                  imageUrl={item.ThmubnailUrl}
                  subtitle={item.CategoryPublicName}
                  title={item.Title}
                  onClick={() => {
                    postMessage({
                      action: 'navigateApp',
                      data: {
                        key: 'BlogWebviewPage',
                        name: 'BlogWebviewPage',
                        params: {
                          url: item.Url,
                        },
                      },
                    });
                  }}
                />
              ))}
            </div>
            <ImageBannerContainer
              variant={'newsLetter'}
              title={'셀박레터 구독하기'}
              description={'이커머스 소식을 빠르게 접하세요!'}
              onClick={() => {
                postMessage({
                  action: 'appBottomSheetWebview',
                  data: {
                    url: 'https://sellerboxletter.stibee.com/subscribe/',
                  },
                });
              }}
              className="my-[16px]"
            />
            <div className="mb-[20px] flex w-full flex-col gap-[12px]">
              {blogList.slice(2, 4).map((item) => (
                <BlogItem
                  imageUrl={item.ThmubnailUrl}
                  subtitle={item.CategoryPublicName}
                  title={item.Title}
                  onClick={() => {
                    postMessage({
                      action: 'navigateApp',
                      data: {
                        key: 'BlogWebviewPage',
                        name: 'BlogWebviewPage',
                        params: {
                          url: item.Url,
                        },
                      },
                    });
                  }}
                />
              ))}
            </div>
            {blogLastNumber >= 9 && (
              <div className="mb-[20px] flex w-full flex-col gap-[12px]">
                {blogList.slice(5, blogLastNumber).map((item) => (
                  <BlogItem
                    imageUrl={item.ThmubnailUrl}
                    subtitle={item.CategoryPublicName}
                    title={item.Title}
                    onClick={() => {
                      postMessage({
                        action: 'navigateApp',
                        data: {
                          key: 'BlogWebviewPage',
                          name: 'BlogWebviewPage',
                          params: {
                            url: item.Url,
                          },
                        },
                      });
                    }}
                  />
                ))}
              </div>
            )}
            {blogLastNumber <= blogList.length && (
              <Button
                variant={'OutlineLow'}
                height={'44'}
                width={'full'}
                onClick={() => {
                  setBlogLastNumber((prev) => prev + 4);
                }}
              >
                블로그 글 더보기
              </Button>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default DiscoverMain;
