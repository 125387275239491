/** 초 단위(s) -> 'M:SS' 포맷으로 파싱 */
export const secondsParser = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // 두 자리로 맞추기 위해 padStart를 사용
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${minutes}:${formattedSeconds}`;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const year = String(date.getFullYear()).slice(2);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const formatDateKorean = (dateString: string): string => {
  const date = new Date(dateString);

  const year = String(date.getFullYear()).slice(0, 4);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}년 ${month}월 ${day}일`;
};

export const autoComma = (amount: number | string): string => {
  if (typeof amount == 'number') {
    return amount.toLocaleString('ko-KR');
  } else {
    return amount;
  }
};

// Date 타입 -> "2024-12-01T00:00:00"
export const formatDateToKST = (date: Date) => {
  const formatter = new Intl.DateTimeFormat('ko-KR', {
    timeZone: 'Asia/Seoul',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // 24시간 형식
  });

  const parts = formatter.formatToParts(date);

  const year = parts.find((part) => part.type === 'year')?.value;
  const month = parts.find((part) => part.type === 'month')?.value;
  const day = parts.find((part) => part.type === 'day')?.value;
  const rawHour = parts.find((part) => part.type === 'hour')?.value;

  // `24`인 경우 `00`으로 변경
  const hour = rawHour === '24' ? '00' : rawHour;
  const minute = parts.find((part) => part.type === 'minute')?.value;
  const second = parts.find((part) => part.type === 'second')?.value;

  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

// "2024-12-01T00:00:00" -> "2024년 12월"
export const formatKSTtoYearMonth = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return `${year}년 ${month}월`;
};

// "2024-09-22T00:00:00" -> "9월22일"
export const formatKSTtoMonthDay = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month}월 ${day}일`;
};

// '2023-01' -> '1월'
export const formatMonth = (dateString: string) => {
  const month = dateString.split('-')[1];
  return `${parseInt(month)}월`;
};
