import { toast } from 'react-toastify';

type ToastType = 'default' | 'success' | 'warning';

export const useToast = () => {
  const showToast = (type: ToastType, text: string) => {
    toast.dismiss();

    switch (type) {
      case 'success':
        toast(`✅ ${text}`);
        return;
      case 'warning':
        toast(`⚠️ ${text}`);
        return;
      default:
        toast(text);
        return;
    }
  };

  return { showToast };
};
