import { Bar } from 'react-chartjs-2';
import ChartDeferred from 'chartjs-plugin-deferred';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { autoComma, formatMonth } from '@/utils/parser';
import { MonthlySales } from '@/types/statistics';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartDeferred,
  ChartDataLabels,
);

interface BarChartProps {
  monthlySalesList: Array<MonthlySales>;
  onClickBar: (salesData: MonthlySales) => void;
  activedMonthlySales: MonthlySales;
}

export const BarChart = ({ monthlySalesList, onClickBar, activedMonthlySales }: BarChartProps) => {
  const labels = monthlySalesList?.map((item) => formatMonth(item.Month));
  const salesData = monthlySalesList?.map((item) => {
    if (item.SalesAmount > 0) {
      return item.SalesAmount;
    } else {
      return 499999;
    }
  });

  const data: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: '월 매출',
        data: salesData,
        backgroundColor: (context) => {
          const activedIndex = monthlySalesList.indexOf(activedMonthlySales);
          if (context.dataIndex === activedIndex) {
            return '#FFBA74';
          } else {
            return '#EFEFEF';
          }
        },
        borderWidth: 0,
        barThickness: 30,
        borderRadius: {
          topLeft: 6,
          topRight: 6,
          bottomLeft: 2,
          bottomRight: 2,
        },
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        align: 'top',
        anchor: 'end',
        font: {
          size: 10,
          weight: 500,
          lineHeight: '16px',
        },
        color: '#555555',
        formatter: (amount: number) => {
          if (amount === 499999) {
            return '0원';
          } else if (amount >= 10000) {
            const unit = Math.floor(amount / 10000);
            return `${autoComma(unit)}만원`;
          } else if (amount > 0) {
            const unit = Math.floor(amount / 1000);
            return `${autoComma(unit)}천원`;
          }
        },
        offset: 0,
      },
    },
    scales: {
      x: {
        type: 'category',
        border: {
          width: 1,
          color: '#EFEFEF',
        },
        grid: {
          display: false,
        },
        ticks: {
          color: '#53555E',
        },
      },
      y: {
        display: false,
        ticks: {
          stepSize: 15000000,
        },
      },
    },
    onClick: (_, elements) => {
      if (!elements.length) return;

      const element = elements[0];
      const index = element.index;
      const clickedData = monthlySalesList[index];

      onClickBar(clickedData);
    },
    animation: {
      duration: 0,
    },
    elements: {
      bar: {
        // pressed 시, 어두워지는 기존 스타일 제거
        hoverBackgroundColor: (context) => {
          const activedIndex = monthlySalesList.indexOf(activedMonthlySales);
          if (context.dataIndex === activedIndex) {
            return '#FFBA74';
          } else {
            return '#EFEFEF';
          }
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
  };

  return (
    <div className="h-[156px] w-full">
      <Bar data={data} options={options} />
    </div>
  );
};
