import { PeriodMarketListContainerProps, SalesMarket } from '@/types/statistics';
import { getPercent } from '../../_utils';
import { autoComma } from '@/utils/parser';
import ColorDistributionBar from '../../_components/ColorDistributionBar';

const PeriodMarketListContainer = ({
  salesMarketList,
  activedMonthlySalesAmount,
}: PeriodMarketListContainerProps) => {
  return (
    <div className="flex flex-col">
      <ColorDistributionBar
        salesItemList={salesMarketList}
        activedMonthlySalesAmount={activedMonthlySalesAmount}
      />
      <div className="flex flex-col gap-[20px] pb-[20px]">
        {salesMarketList.map((item: SalesMarket, index) => {
          const percent = getPercent(item.SalesAmount, activedMonthlySalesAmount);
          return (
            <div
              key={`${item.MarketName} ${item.SalesAmount} ${index}`}
              className="flex items-center justify-between"
            >
              <div className="flex items-center gap-[10px]">
                <img
                  src={item.MarketIconUrl}
                  alt="마켓 아이콘"
                  className="h-[26px] w-[26px] rounded-[13px] border-[1px] border-gray-150"
                  width={26}
                  height={26}
                />
                <span className="text-gray-800 Body6M14">{item.MarketName}</span>
              </div>
              <span className="text-gray-800 Body6M14">{`${autoComma(
                item.SalesAmount,
              )}원 (${percent}%)`}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PeriodMarketListContainer;
