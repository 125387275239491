interface PostMessageProps {
  /** web -> app 방향으로 전달하기 위한 액션 메시지 종류 */
  action: ActionType;
  /** 액션에 사용할 데이터 */
  data: NavigateData | ResetData | AppBottomSheetWebviewData | string | AsyncStorageData;
}
/**
 * webview 통신 액션
 * navigateApp : stack 추가해서 이동
 * backApp : stack 뒤로 가기
 * resetApp : stack reset 해서 재구성
 * appBottomSheetWebview : app bottom sheet로 webview 화면 띄우기
 * saveAsyncStorage : {key : value} 형식으로 app의 async storage 에 데이터 저장
 * isWebBottomSheetOpen : web bottom sheet 활성화 여부
 * callApi : app에 있는 api 호출
 */
type ActionType =
  | 'navigateApp'
  | 'backApp'
  | 'resetApp'
  | 'appBottomSheetWebview'
  | 'saveAsyncStorage'
  | 'isWebBottomSheetOpen'
  | 'callApi';

interface NavigateData {
  key: string;
  name: string;
  params?: { [key: string]: string };
}

interface ResetData {
  index: number;
  routes: Array<NavigateData>;
}

interface AppBottomSheetWebviewData {
  /** 사용할 url */
  url: string;
}

interface AsyncStorageData {
  [key: string]: string | Array<string>;
}

const useWebview = () => {
  const postMessage = ({ action, data }: PostMessageProps) => {
    const message = JSON.stringify({
      [action]: data,
    });
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    }
  };
  return { postMessage };
};

export default useWebview;
