import { SelectStatusType } from '@/types/discover';
import { autoComma } from '@/utils/parser';
import { cn } from '@/utils/tailwind';

interface GameCardProps {
  keyword: string;
  count: number;
  selectStatus: SelectStatusType;
  selectedKeyword: string;
  onClick: () => void;
  isLoading: boolean;
}

const GameCard = ({
  keyword,
  count,
  selectStatus,
  selectedKeyword,
  onClick,
  isLoading,
}: GameCardProps) => {
  return (
    <div
      className={cn(
        'flex h-[216px] w-full flex-col items-center justify-center gap-[4px] rounded-[21px] bg-gray-50 px-[20px] ring-[1px] ring-gray-200',
        selectedKeyword === keyword &&
          selectStatus === 'correct' &&
          'bg-blue-100 ring-[1px] ring-blue-400',
        selectedKeyword === keyword &&
          selectStatus === 'wrong' &&
          'bg-red-100 ring-[1px] ring-red-400',
        selectedKeyword !== keyword && selectStatus !== 'default' && 'ring-0',
        isLoading && 'pointer-events-none animate-pulse bg-gray-100 ring-0',
      )}
      onClick={onClick}
    >
      <span
        className={cn(
          'text-gray-800 Title3S18',
          selectedKeyword === keyword && selectStatus === 'correct' && 'text-blue-400',
          selectedKeyword === keyword && selectStatus === 'wrong' && 'text-red-400',
        )}
      >
        {keyword}
      </span>
      {selectStatus !== 'default' && (
        <span
          className={cn(
            'text-gray-800 Body6M14',
            selectedKeyword === keyword && selectStatus === 'correct' && 'text-blue-400',
            selectedKeyword === keyword && selectStatus === 'wrong' && 'text-red-400',
          )}
        >
          검색량 : {autoComma(count)}
        </span>
      )}
    </div>
  );
};

export default GameCard;
