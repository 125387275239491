import { forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '@/utils/tailwind';

const buttonVariants = cva('select-none transition-colors focus:outline-none', {
  variants: {
    variant: {
      SolidHigh: 'text-white bg-primary-500 active:bg-primary-400 disabled:bg-v3-gray-200',
      SolidLow:
        'text-v3-gray-700 bg-v3-gray-150 active:bg-v3-gray-200 disabled:text-white disabled:bg-v3-gray-200',
      OutlineHigh:
        'text-primary-500 bg-white active:text-primary-400 active:bg-v3-gray-150 disabled:text-v3-gray-250 disabled:bg-white border-[1px] disabled:border-v3-gray-200 border-primary-500 active:border-primary-400',
      OutlineLow:
        'text-v3-gray-700 bg-white active:bg-v3-gray-150 disabled:text-v3-gray-250 disabled:bg-white border-[1px] disabled:border-v3-gray-200 border-v3-gray-200 active:border-v3-gray-200',
    },
    width: {
      full: 'w-full',
      '18': 'px-[18px]',
      '13': 'px-[13px]',
      '10': 'px-[10px]',
      '8': 'px-[8px]',
    },
    height: {
      '52': 'h-[52px] Body1S16 rounded-[16px]',
      '44': 'h-[44px] Body3S15 rounded-[12px]',
      '38': 'h-[38px] Body4B14 rounded-[8px]',
      '34': 'h-[34px] Body5S14 rounded-[8px]',
      '28': 'h-[28px] Caption1M12 rounded-[8px]',
      '26': 'h-[26px] Caption1M12 rounded-[8px]',
    },
  },
});

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    isLoading?: boolean;
    loadingClassName?: string;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      loadingClassName,
      variant,
      width,
      height,
      isLoading,
      disabled,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={cn(
          buttonVariants({
            variant,
            width,
            height,
            className,
          }),
        )}
        disabled={isLoading ? true : disabled}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
