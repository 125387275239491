import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: UserData = {
  CplatToken: '',
  VerificationToken: '',
  Email: '',
  UserNo: '',
  Password: '',
  Name: '',
  PhoneNumber: '',
  UseYn: '',
  LastLoginDate: '',
  SnsType: '',
  SnsToken: '',
  PushAlertToken: '',
  InvitationCode: '',
  BizNo: '',
  BizType: '',
  BizNm: '',
  StoreName: '',
  StoreCategory1: '',
  StoreCategory2: '',
  StoreSinceWhenSales: '',
  StoreThumbnailImageUrl: '',
  AdAgreeYn: 'N',
  DailyReportYn: 'Y',
  DailyAmountAlertYn: 'Y',
  DailyAlertAmount: 0,
  UndeliveryAlertYn: 'Y',
  UndeliveryAlertTime: '',
  OrderRealtimeAlertYn: 'Y',
  RefundRealtimeAlertYn: 'Y',
  ReviewRealtimeAlertYn: 'Y',
  ReviewLowScoreAlertYn: 'Y',
  ReviewLowScoreCanBeSet: 0,
  KcbCreditRetrieveAgreeYn: 'Y',
  GlobalIncludeShippingFeeYn: 'Y',
  RegisterDate: '',
  ServiceInfo: {
    AiSellerInfo: {
      IsSubcribed: false,
    },
    SellkeyInfo: {
      IsSubcribed: false,
      FirstLoginDate: '',
      IsTrialPeriod: false,
    },
  },
  SellkeyMonitoringAlertYn: 'Y',
  PreferShippingCompanyList: [],
  EmailAddreess: '',
};

export const UserState = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (_: UserData, action: PayloadAction<UserData>) => {
      return { ...action.payload };
    },
    initUser: () => {
      return { ...initialState };
    },
  },
});

export const { setUser, initUser } = UserState.actions;
const UserReducer = UserState.reducer;

export default UserReducer;
