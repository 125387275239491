import Input from '@/components/V3/Input';
import AppHeader from '@/containers/V3/AppHeader/AppHeader';
import useWebview from '@/hooks/V3/useWebview';
import { useAppSelector } from '@/redux/store';
import { recentSearchKeywordListAsyncStorageState, safeAreaInsetsState } from '@/store/atom';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import SearchIcon from '@/assets/icons/v3/SearchIcon.svg?react';
import CloseGrayIcon from '@/assets/icons/v3/CloseGrayIcon.svg?react';

const SearchProduct = () => {
  const user = useAppSelector((state) => state.user);
  const inputRef = useRef<HTMLInputElement>(null);
  const { postMessage } = useWebview();
  const recentSearchKeywordListAsyncStorage = useRecoilValue(
    recentSearchKeywordListAsyncStorageState,
  );
  const [recentSearchKeywordList, setRecentSearchKeywordList] = useState<Array<string>>(
    recentSearchKeywordListAsyncStorage,
  );
  const [input, setInput] = useState<string>('');
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedInput = e.target.value.replace(/\s{2,}/g, ' ');
    setInput(cleanedInput);
  };

  const handleSearchWebview = (keyword: string) => {
    postMessage({
      action: 'navigateApp',
      data: {
        key: 'WebviewPage',
        name: 'WebviewPage',
        params: {
          // url: `http://10.133.63.239:3000/mobileKeywordAnalyze?cplatToken=${user.CplatToken}&keyword=${keyword}`,
          // url: `https://sellkey-dev.sellerbox.io/mobileKeywordAnalyze?cplatToken=${user.CplatToken}&keyword=${keyword}`,
          url: `https://sellkey.sellerbox.io/mobileKeywordAnalyze?cplatToken=${user.CplatToken}&keyword=${keyword}`,
        },
      },
    });
  };

  const handleEnter = (input: string) => {
    let filteredList;
    let updatedList;
    if (recentSearchKeywordList.length > 0 && recentSearchKeywordList.length <= 9) {
      filteredList = recentSearchKeywordList.filter((keyword) => keyword !== input);
      updatedList = [input, ...filteredList];
    } else if (recentSearchKeywordList.length > 9) {
      filteredList = recentSearchKeywordList.filter((keyword) => keyword !== input);
      filteredList = filteredList.slice(0, 9);
      updatedList = [input, ...filteredList];
    } else {
      updatedList = [input];
    }
    // FIXME: 이 시점에서 로컬에서는 잘 되지만 실제 디바이스에서 에러가 뜨고 있어서 추후 확인 필요
    postMessage({
      action: 'saveAsyncStorage',
      data: {
        recentSearchKeywordList: updatedList,
      },
    });

    handleSearchWebview(input);
    setInput('');
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      if (data['recentSearchKeywordListRecoil']) {
        setRecentSearchKeywordList(data['recentSearchKeywordListRecoil']);
      }
    };

    window.addEventListener('message', handleMessage);
    document.addEventListener('message', handleMessage as EventListener);
    return () => {
      window.removeEventListener('message', handleMessage);
      document.addEventListener('message', handleMessage as EventListener);
    };
  }, []);

  return (
    <main
      className="flex h-screen select-none flex-col bg-white px-[20px]"
      style={{
        paddingTop: safeAreaInsets.topInset,
        paddingBottom: safeAreaInsets.bottomInset,
      }}
    >
      <AppHeader title={'상품 검색'} className="mb-[18px]" />
      <Input
        ref={inputRef}
        variant={'underline'}
        placeholder="분석하고 싶은 키워드를 입력하세요"
        value={input}
        onChange={handleInput}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (input && e.key === 'Enter') {
            handleEnter(input);
          }
        }}
        className={'pr-[30px]'}
        InputProps={{
          endAdornment: (
            <span
              onClick={() => {
                if (input) {
                  handleEnter(input);
                } else {
                  inputRef.current?.focus();
                }
              }}
            >
              <SearchIcon />
            </span>
          ),
        }}
        enterKeyHint={'go'}
      />

      <div className="text-v3-gray-750 mb-[12px] mt-[20px] Caption1M12">최근 검색 키워드</div>
      <div className="flex flex-col gap-[16px]">
        {recentSearchKeywordList?.map((keyword, index) => (
          <div
            className="text item-center flex justify-between Body6M14"
            key={`${keyword} ${index}`}
          >
            <div
              onClick={() => {
                handleEnter(keyword);
              }}
            >
              {keyword}
            </div>
            <span
              onClick={() => {
                const filteredList = recentSearchKeywordList.filter((item) => item !== keyword);
                postMessage({
                  action: 'saveAsyncStorage',
                  data: {
                    recentSearchKeywordList: filteredList,
                  },
                });
              }}
            >
              <CloseGrayIcon />
            </span>
          </div>
        ))}
      </div>
    </main>
  );
};

export default SearchProduct;
