import Tab from '@/components/V3/Tab';
import { autoComma } from '@/utils/parser';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  MonthlySales,
  PeriodTabType,
  SalesMarket,
  SalesProduct,
  SalesStore,
} from '@/types/statistics';
import PeriodStoreListContainer from './_containers/PeriodStoreListContainer';
import PeriodProductListContainer from './_containers/PeriodProductListContainer';
import PeriodMarketListContainer from './_containers/PeriodMarketListContainer';
import ArrowBlueDownIcon from '@/assets/icons/V3/arrow/ArrowBlueDownIcon.svg?react';
import ArrowRedUpIcon from '@/assets/icons/V3/arrow/ArrowRedUpIcon.svg?react';
import { cn } from '@/utils/tailwind';
import { BarChart } from '@/components/V3/Chart/BarChart';
import ExampleStoreListContainer from './_containers/ExampleStoreListContainer';

interface StatisticsPeriodContainerProps {
  monthlySalesList: Array<MonthlySales>;
  salesMarketList: Array<SalesMarket>;
  salesProductList: Array<SalesProduct>;
  salesStoreList: Array<SalesStore>;
  activedMonthlySales: MonthlySales;
  setActivedMonthlySales: Dispatch<SetStateAction<MonthlySales>>;
}

const StatisticsPeriodContainer = ({
  monthlySalesList,
  salesMarketList,
  salesProductList,
  salesStoreList,
  activedMonthlySales,
  setActivedMonthlySales,
}: StatisticsPeriodContainerProps) => {
  const [activedItem, setActivedItem] = useState<PeriodTabType>('스토어별');

  const { difference, state } = getDiffrenceSales(monthlySalesList, activedMonthlySales);
  const activedMonthlySalesAmount = getActivedMonthlySalesAmount(activedMonthlySales);

  const renderList = (activedItem: PeriodTabType) => {
    if (activedMonthlySalesAmount <= 0) {
      return <ExampleStoreListContainer />;
    }

    switch (activedItem) {
      case '스토어별':
        return (
          <PeriodStoreListContainer
            activedMonthlySalesAmount={activedMonthlySalesAmount}
            salesStoreList={salesStoreList}
          />
        );
      case '마켓별':
        return (
          <PeriodMarketListContainer
            activedMonthlySalesAmount={activedMonthlySalesAmount}
            salesMarketList={salesMarketList}
          />
        );
      case '상품별':
        return (
          <PeriodProductListContainer
            activedMonthlySalesAmount={activedMonthlySalesAmount}
            salesProductList={salesProductList}
          />
        );
    }
  };

  function getDiffrenceSales(
    monthlySalesList: Array<MonthlySales>,
    activedMonthlySales: MonthlySales,
  ): {
    difference: string;
    state: 'positive' | 'negative' | 'neutral';
  } {
    const activedIndex = monthlySalesList.indexOf(activedMonthlySales);
    const difference =
      monthlySalesList[activedIndex]?.SalesAmountUntilDay -
      monthlySalesList[activedIndex - 1]?.SalesAmountUntilDay;
    const absDifference = Math.abs(difference);

    if (difference > 0) {
      return {
        difference: autoComma(absDifference),
        state: 'positive',
      };
    } else if (difference < 0) {
      return {
        difference: autoComma(absDifference),
        state: 'negative',
      };
    } else {
      return {
        difference: '0',
        state: 'neutral',
      };
    }
  }

  const getIsCurrnetMonth = () => {
    const activedMonth = Number(activedMonthlySales.Month.split('-')[1]);
    const today = new Date();
    const todayMonth = today.getMonth() + 1;
    return todayMonth === activedMonth;
  };

  function getActivedMonthlySalesAmount(activedMonthlySales: MonthlySales) {
    const activedIndex = monthlySalesList.indexOf(activedMonthlySales);
    return monthlySalesList[activedIndex].SalesAmount;
  }

  useEffect(() => {
    if (activedMonthlySalesAmount <= 0) {
      setActivedItem('스토어별');
    }
  }, [activedMonthlySalesAmount]);

  return (
    <>
      <div className="flex flex-grow flex-col overflow-y-auto scrollbar-hide">
        <div className="flex flex-col gap-[4px] px-[20px] pt-[24px]">
          <div className="text-gray-800 Title3S18">
            {autoComma(activedMonthlySales?.SalesAmount)}원
          </div>
          <div
            className={cn(
              'flex items-center gap-[4px] Caption1M12',
              state === 'negative' ? 'text-blue-400' : 'text-red-400',
            )}
          >
            {state === 'neutral' ? (
              <>
                {getIsCurrnetMonth() ? (
                  <span>지난달 같은 기간과 동일해요</span>
                ) : (
                  <span>지난달과 동일해요</span>
                )}
              </>
            ) : (
              <>
                {getIsCurrnetMonth() ? (
                  <span>지난달 같은 기간 보다</span>
                ) : (
                  <span>지난달 보다</span>
                )}
                <div className="flex items-center">
                  {state === 'negative' ? <ArrowBlueDownIcon /> : <ArrowRedUpIcon />}

                  <span>{difference}</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="px-[20px]">
          <BarChart
            monthlySalesList={monthlySalesList.slice(-(monthlySalesList.length - 1))} // 그래프에서 6개월치만 보여주기 위해 첫번째 값 제거
            onClickBar={(salesData) => setActivedMonthlySales(salesData)}
            activedMonthlySales={activedMonthlySales}
          />
        </div>
        <div className="mt-[24px] h-[12px] w-full flex-none bg-gray-100"></div>
        <div
          className={cn(
            'sticky top-0 bg-white px-[20px] pt-[20px] z-1',
            activedMonthlySalesAmount <= 0 && 'pointer-events-none',
          )}
        >
          <Tab
            variant={'button'}
            activedItem={activedItem}
            setActivedItem={setActivedItem}
            itemList={['스토어별', '마켓별', '상품별']}
          />
        </div>
        <div className="px-[20px]">
          <div>{renderList(activedItem)}</div>
        </div>
      </div>
    </>
  );
};

export default StatisticsPeriodContainer;
