import { safeAreaInsetsState } from '@/store/atom';
import { cn } from '@/utils/tailwind';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactNode, RefObject, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

interface BottomSheetProps {
  bottomSheetRef: RefObject<HTMLDivElement>;
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  className?: string;
}

// Webview 에서 Bottom Sheet 내부에 있는 입력폼 때문에
// 키보드가 활성화되는 경우에 키보드를 피하는 로직을 추가하기 위해
// 커스텀 bottom sheet을 따로 만듦
const BottomSheet = ({ bottomSheetRef, children, open, onClose, className }: BottomSheetProps) => {
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);

  useEffect(() => {
    const handleVisualViewportResize = () => {
      if (window.visualViewport && bottomSheetRef.current) {
        const currentVisualViewport = window.visualViewport.height;
        bottomSheetRef.current.style.height = `${
          currentVisualViewport - safeAreaInsets.topInset
        }px`;
        window.visualViewport.onscroll = () => {
          window.scrollTo(0, 0);
        };
      }
    };
    if (window.visualViewport) {
      window.visualViewport.onresize = handleVisualViewportResize;
    }
    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleVisualViewportResize);
      }
    };
  }, []);

  return (
    // framer-motion에서 컴포넌트 조건부 렌더링 시 AnimatePresence로 감싸주기
    <AnimatePresence mode="wait">
      {open && (
        <motion.div
          ref={bottomSheetRef}
          className="insets-0 absolute flex h-full w-full items-end bg-black bg-opacity-50 z-2"
          onClick={onClose}
          style={{
            height: `calc(100% - ${safeAreaInsets.topInset}px)`,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            className={cn('w-full rounded-t-[28px] bg-white px-[20px] py-[30px]', className)}
            onClick={(e) => e.stopPropagation()}
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{
              type: 'spring',
              stiffness: 300,
              damping: 40,
            }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BottomSheet;
