import { ToastContainer, Slide, toast } from 'react-toastify';

interface ToastProps {
  topInset?: number;
}

const Toast = ({ topInset }: ToastProps) => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={2000}
      toastClassName={() =>
        'rounded-[4px] bg-gray-800/80 text-white py-[13px] px-[16px] text-center Body6M14 mx-[20px] w-[calc(100%-40px)]'
      }
      toastStyle={{
        marginTop: topInset,
      }}
      transition={Slide}
      closeButton={false}
      hideProgressBar={true}
      onClick={() => toast.dismiss()}
      limit={2}
    />
  );
};

export default Toast;
