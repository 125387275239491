import { useState } from 'react';
import { useToast } from './useToast';

const useCopy = () => {
  const { showToast } = useToast();
  const [isCopying, setIsCopying] = useState(false);

  const copyToClipboard = (copyText: string, toastMessage: string) => {
    if (isCopying) return;
    setIsCopying(true);

    // 최신 Clipboard api
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          showToast('success', toastMessage);
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        })
        .finally(() => {
          setTimeout(() => setIsCopying(false), 3000);
        });
    } else {
      // Clipboard api 미지원 시, 레거시 document 방식으로 진행
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        showToast('success', toastMessage);
      } catch (err) {
        console.error('Failed to copy using execCommand: ', err);
      } finally {
        document.body.removeChild(textArea);
        setTimeout(() => setIsCopying(false), 3000);
      }
    }
  };

  return { copyToClipboard };
};

export default useCopy;
