import { ColorScheme } from '@/types/statistics';
import { formatDateToKST } from '@/utils/parser';
import { Dispatch, SetStateAction } from 'react';

interface HandleMoveDateProps {
  action: 'up' | 'down';
  activedDate: string;
  setActivedDate: Dispatch<SetStateAction<string>>;
}

export const handleMoveMonth = ({ action, activedDate, setActivedDate }: HandleMoveDateProps) => {
  const currentDate = new Date(activedDate);
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const today = new Date();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();

  const monthsDifference = (todayYear - currentYear) * 12 + todayMonth - currentMonth;

  switch (action) {
    case 'down':
      if (monthsDifference >= 12) {
        return;
      }
      setActivedDate((prev) => {
        const date = new Date(prev);
        date.setMonth(date.getMonth() - 1);
        return formatDateToKST(date);
      });

      break;

    case 'up':
      if (monthsDifference <= 0) {
        return;
      }
      setActivedDate((prev) => {
        const date = new Date(prev);
        date.setMonth(date.getMonth() + 1);
        return formatDateToKST(date);
      });

      break;
  }
};

export const handleMoveDay = ({ action, activedDate, setActivedDate }: HandleMoveDateProps) => {
  const currentDate = new Date(activedDate);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const daysDifference = Math.floor(
    (today.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
  );

  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  switch (action) {
    case 'down':
      if (daysDifference >= 365) {
        return;
      }
      setActivedDate((prev) => {
        const date = new Date(prev);
        date.setDate(date.getDate() - 1);
        return formatDateToKST(date);
      });

      break;
    case 'up':
      if (currentDate.getDate() >= lastDayOfMonth.getDate()) {
        return;
      }
      setActivedDate((prev) => {
        const date = new Date(prev);
        date.setDate(date.getDate() + 1);

        return formatDateToKST(date);
      });

      break;
  }
};

export const getActivePeriod = (
  activedDate: string,
  periodMonthLength: number,
  isdateToToday: boolean,
) => {
  const activeDateObj = new Date(activedDate);

  const activeYear = activeDateObj.getFullYear();
  const activeMonth = activeDateObj.getMonth();

  // 시작 날짜 (현재 월의 첫 번째 날)
  const dateFrom = new Date(Date.UTC(activeYear, activeMonth - (periodMonthLength - 1), 1));

  // isdateToToday가 true면 dateTo를 오늘 날짜로, 아니면 이번 달 마지막 날짜로 설정
  const dateTo = isdateToToday
    ? new Date() // 오늘 날짜
    : new Date(Date.UTC(activeYear, activeMonth + 1, 0)); // 이번 달 마지막 날

  return {
    dateFrom: dateFrom.toISOString(),
    dateTo: dateTo.toISOString(),
  };
};

export const getColor = (index: number): ColorScheme => {
  const number = index % 5;
  switch (number) {
    case 0:
      return {
        border: 'border-[#FFA0A0]',
        background: 'bg-[#FFDBDB]',
        graph: 'bg-[#FFC4CC]',
      };
    case 1:
      return {
        border: 'border-[#75ABDD]',
        background: 'bg-[#C4E2FF]',
        graph: 'bg-[#C4E2FF]',
      };
    case 2:
      return {
        border: 'border-[#DCD277]',
        background: 'bg-[#FFFACD]',
        graph: 'bg-[#FFFACD]',
      };
    case 3:
      return {
        border: 'border-[#C0A7D9]',
        background: 'bg-[#EBD8FF]',
        graph: 'bg-[#EBD8FF]',
      };
    case 4:
    default:
      return {
        border: 'border-[#A8C790]',
        background: 'bg-[#E2F6D2]',
        graph: 'bg-[#E2F6D2]',
      };
  }
};

export const getPercent = (currentAmount: number, totalAmount: number): string => {
  const percent = (currentAmount / totalAmount) * 100;
  return percent.toFixed(1);
};
