import usePostMessage from '@/hooks/usePostMessage';
import MorePlusOrangeIcon from '@/assets/icons/MorePlusOrangeIcon.svg?react';
import { useEffect, useState } from 'react';
import { InquiryCommentTemplate } from '@/types/inquiry';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useAppSelector } from '@/redux/store';
import EmptyFaceIcon from '@/assets/icons/EmptyFaceIcon.svg?react';
import GlobalSpinner from '@/components/organisms/GlobalSpinner';
import ArrowBlackForwordIcon from '@/assets/icons/ArrowBlackForwordIcon.svg?react';
import { useToast } from '@/hooks/useToast';

const TemplatesInquiryList = () => {
  const { navigateApp } = usePostMessage();
  const [inquiryCommentTemplateList, setInquiryCommentTemplateList] = useState<
    Array<InquiryCommentTemplate>
  >([]);
  const user = useAppSelector((state) => state.user);
  const { toast } = useToast();

  const { mutate: getInquiryCommentTemplate, isPending: isPendingInquiryCommentTemplate } = usePost(
    CPLAT_API_ROUTES.getInquiryCommentTemplate,
  );

  const handleGetInquiryCommentTemplate = () => {
    getInquiryCommentTemplate(
      {
        cplatToken: user.CplatToken,
      },
      {
        onSuccess(res) {
          const code = res.data.code;
          if (code === '200') {
            const InquiryCommentTemplateList = res.data.data[0].InquiryCommentTemplateList;
            setInquiryCommentTemplateList(InquiryCommentTemplateList);
          } else {
            setInquiryCommentTemplateList([]);
          }
        },
      },
    );
  };

  useEffect(() => {
    handleGetInquiryCommentTemplate();
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      if (data['updateTemplateData']) {
        handleGetInquiryCommentTemplate();
        if (data['updateTemplateData'] === 'register') {
          toast({
            icon: '✅',
            title: '템플릿이 추가되었어요.',
            variant: 'dark',
          });
        } else if (data['updateTemplateData'] === 'update') {
          toast({
            icon: '✅',
            title: '템플릿이 수정되었어요.',
            variant: 'dark',
          });
        } else if (data['updateTemplateData'] === 'delete') {
          toast({
            icon: '✅',
            title: '템플릿이 삭제되었어요.',
            variant: 'dark',
          });
        }
      }
    };

    window.addEventListener('message', handleMessage);
    document.addEventListener('message', handleMessage as EventListener);
    return () => {
      window.removeEventListener('message', handleMessage);
      document.addEventListener('message', handleMessage as EventListener);
    };
  }, []);

  return (
    <div className="flex h-screen w-full flex-col overflow-y-auto bg-white px-[20px]">
      <div
        className="flex w-fit items-center gap-[8px] pb-[12px] pt-[20px]"
        onClick={() => {
          navigateApp('TemplatesInquiryDetail');
        }}
      >
        <MorePlusOrangeIcon />
        <span className="text-primary-500 Body3S15">템플릿 추가하기</span>
      </div>
      {inquiryCommentTemplateList.length > 0 ? (
        <div>
          {inquiryCommentTemplateList.map((item) => (
            <div
              className="flex items-center justify-between py-[17px] text-gray-700 Body2M16"
              onClick={() => navigateApp('TemplatesInquiryDetail', item)}
              key={item.TemplateNo}
            >
              {item.Title}
              <span>
                <ArrowBlackForwordIcon />
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex w-full flex-grow flex-col items-center justify-center gap-[20px] text-gray-750 Body1S16">
          <EmptyFaceIcon />
          <div>등록된 템플릿이 없어요</div>
        </div>
      )}
      {isPendingInquiryCommentTemplate && <GlobalSpinner />}
    </div>
  );
};

export default TemplatesInquiryList;
