import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './index.css';
import { cn } from '@/utils/tailwind';
import { StatisticsCalendarProps } from '@/types/statistics';
import { autoComma, formatDateToKST } from '@/utils/parser';

export const StatisticsCalendar = ({
  activeStartDate,
  calendarDailyList,
  onClickDay,
  className,
}: StatisticsCalendarProps) => {
  return (
    <div className={cn('calendar-container', className)}>
      <Calendar
        activeStartDate={activeStartDate}
        calendarType="gregory"
        showNavigation={false}
        onClickDay={(date) => {
          // 다른 월 클릭 방지
          const month = date.getMonth();
          if (month !== new Date(activeStartDate).getMonth()) return;
          onClickDay(date);
        }}
        tileContent={({ date }) => {
          const day = date.getDate();
          const month = date.getMonth();
          const currentItem = calendarDailyList.find(
            (item) => item.Date.slice(0, 10) === formatDateToKST(date).slice(0, 10),
          );
          const salesAmount = currentItem?.SalesAmount || 0;
          const settleCompletionAmount = currentItem?.SettleCompletionAmount || 0;

          return (
            <div className="tile-content">
              <div
                className={cn(
                  'day',
                  day === new Date().getDate() && month === new Date().getMonth() && 'today',
                  month !== new Date(activeStartDate).getMonth() && 'other-month',
                )}
              >
                {day}
              </div>
              {salesAmount > 0 && <div className={cn('salesAmount')}>{autoComma(salesAmount)}</div>}
              {settleCompletionAmount !== 0 && (
                <div className={cn('settleAmount')}>{autoComma(settleCompletionAmount)}</div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};
