import AppHeader from '@/containers/V3/AppHeader/AppHeader';
import { safeAreaInsetsState } from '@/store/atom';
import { useRecoilValue } from 'recoil';

const AIChatBot = () => {
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);
  return (
    <main
      className="flex h-screen select-none flex-col gap-[12px] bg-white"
      style={{
        paddingTop: safeAreaInsets.topInset,
        paddingBottom: safeAreaInsets.bottomInset,
      }}
    >
      <div className="px-[20px]">
        <AppHeader title={'셀러박스 AI 챗봇'} />
      </div>
    </main>
  );
};

export default AIChatBot;
