import Badge from '@/components/V3/Badge';
import { PeriodProductListContainerProps } from '@/types/statistics';
import { autoComma } from '@/utils/parser';
import { cn } from '@/utils/tailwind';
import { getPercent } from '../../_utils';

const PeriodProductListContainer = ({
  salesProductList,
  activedMonthlySalesAmount,
}: PeriodProductListContainerProps) => {
  return (
    <div className="flex flex-col">
      <div className="mt-[24px] flex flex-col gap-[20px] pb-[20px]">
        {salesProductList.map((item, index) => {
          const percent = getPercent(item.SalesAmount, activedMonthlySalesAmount);
          return (
            <div
              key={`${item.MarketName} ${item.SalesAmount} ${index}`}
              className="flex flex-col gap-[4px]"
            >
              <div className="flex items-center gap-[4px]">
                <div
                  className={cn(
                    'flex h-[22px] w-[22px] flex-none items-center justify-center rounded-[4px] !leading-[1px] Caption2B12',
                    index < 3 ? 'bg-red-300 text-gray-50' : 'bg-gray-200 text-gray-600',
                  )}
                >
                  {index + 1}
                </div>
                <div className="line-clamp-1 text-gray-800 Body6M14">{item.ProductName}</div>
              </div>
              <div className="text-gray-600 Body6M14">{item.ProductOptionName}</div>
              <div className="flex flex-col gap-[2px]">
                <div className="flex items-center gap-[4px]">
                  <img
                    src={item.MarketIconUrl}
                    alt="마켓 아이콘"
                    className="h-[16px] w-[16px]"
                    width={16}
                    height={16}
                  />
                  <span className="!leading-[1px] text-gray-500 Caption1M12">{item.StoreName}</span>
                </div>
                <div className="flex items-center gap-[8px]">
                  <div className="text-gray-800 Body6M14">{`${item.SalesCount}건 | ${autoComma(
                    item.SalesAmount,
                  )}원`}</div>
                  <Badge variant={'gray'} height={'26'} width={'6'}>
                    {percent}%
                  </Badge>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PeriodProductListContainer;
