/** 선택 상태 : 기본, 정답, 오답 */
export type SelectStatusType = 'default' | 'correct' | 'wrong';

export interface Ranking {
  GameId: string;
  Score: number;
  Timestamp: string;
  UserId: string;
}

export interface Game {
  keyword: string;
  monthly_search_count: number;
}

export interface BlogPosting {
  CategoryPublicName: string;
  ThmubnailUrl: string;
  Title: string;
  Url: string;
}

export type ApiStatusType = 'loading' | 'success' | 'error';

export interface PersonalData {
  Timestamp: string;
  UserId: string;
  Score: number;
  GameId: string;
  Rank: number;
}

export const PersonalDataInitial: PersonalData = {
  Timestamp: '',
  UserId: '',
  Score: 0,
  GameId: '',
  Rank: 0,
};
