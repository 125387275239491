import ArrowGrayLeftMore from '@/assets/icons/V3/arrow/ArrowGrayLeftMore.svg?react';
import ArrowGrayRightMore from '@/assets/icons/V3/arrow/ArrowGrayRightMore.svg?react';
import SalesIcon from '@/assets/icons/V3/statistics/SalesIcon.svg?react';
import AdjustmentIcon from '@/assets/icons/V3/statistics/AdjustmentIcon.svg?react';
import DisconnectIcon from '@/assets/icons/V3/statistics/DisconnectIcon.svg?react';
import { useEffect, useState } from 'react';
import { autoComma, formatDateToKST, formatKSTtoMonthDay } from '@/utils/parser';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useAppSelector } from '@/redux/store';
import {
  CalendarDetailModalContainerProps,
  Market,
  SalesInfo,
  SalesInfoInitial,
  SettleInfo,
  SettleInfoInitial,
} from '@/types/statistics';
import useWebview from '@/hooks/V3/useWebview';
import { cn } from '@/utils/tailwind';
import { handleMoveDay } from '../../_utils';

const CalendarDetailModalContainer = ({ selectedDetailDay }: CalendarDetailModalContainerProps) => {
  const user = useAppSelector((state) => state.user);
  const formattedSelectedDetailDay = formatDateToKST(selectedDetailDay);
  const [activedDate, setActivedDate] = useState<string>(formattedSelectedDetailDay);
  const [marketList, setMarketList] = useState<Array<Market>>([]);
  const [salesInfo, setSalesInfo] = useState<SalesInfo>(SalesInfoInitial);
  const [settleInfo, setSettleInfo] = useState<SettleInfo>(SettleInfoInitial);
  const { postMessage } = useWebview();

  const { mutate: getCalendarDaily } = usePost(CPLAT_API_ROUTES.getCalendarDaily);

  const handleGetCalendarDaily = (date: string) => {
    getCalendarDaily(
      {
        cplatToken: user.CplatToken,
        dateFrom: date,
        dateTo: date,
      },
      {
        onSuccess(res) {
          const { data, code } = res?.data;
          if (code === '200') {
            const { MarketList, SalesInfo, SettleInfo } = data[0];
            setMarketList(MarketList);
            setSalesInfo(SalesInfo);
            setSettleInfo(SettleInfo);
          } else {
            setMarketList([]);
            setSalesInfo(SalesInfoInitial);
            setSettleInfo(SettleInfoInitial);
          }
        },
      },
    );
  };

  useEffect(() => {
    handleGetCalendarDaily(activedDate);
  }, [activedDate]);

  return (
    <div>
      <div className="relative mx-auto flex w-fit items-center justify-center gap-[8px]">
        <span
          className="absolute left-[-8px] flex h-[32px] w-[32px] items-center justify-center"
          onClick={() => {
            handleMoveDay({
              action: 'down',
              activedDate,
              setActivedDate,
            });
          }}
        >
          <ArrowGrayLeftMore />
        </span>
        <div className="px-[24px] text-gray-800 Title3S18">
          {formatKSTtoMonthDay(activedDate)} 리포트
        </div>
        <span
          className="absolute right-[-8px] flex h-[32px] w-[32px] items-center justify-center"
          onClick={() => {
            handleMoveDay({
              action: 'up',
              activedDate,
              setActivedDate,
            });
          }}
        >
          <ArrowGrayRightMore />
        </span>
      </div>
      <div className="my-[20px] flex flex-col gap-[16px] px-[20px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[8px]">
            <SalesIcon />
            <span className="text-v3-gray-800 Body2M16">매출</span>
          </div>
          <span className="text-gray-700 Body1S16">{`${autoComma(
            salesInfo.TotalSalesAmount,
          )}원 (${autoComma(salesInfo.TotalSalesCount)}건)`}</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[8px]">
            <AdjustmentIcon />
            <span className="text-v3-gray-800 Body2M16">정산</span>
          </div>
          <span className="text-gray-700 Body1S16">{`${autoComma(
            settleInfo.TotalSettleAmount,
          )}원`}</span>
        </div>
      </div>
      <div className="h-[12px] w-full flex-none bg-v3-gray-150"></div>
      <div className="flex max-h-[452px] min-h-[162px] flex-col gap-[20px] overflow-y-auto px-[20px] py-[24px] scrollbar-hide">
        {marketList.map((item: Market, index) => (
          <div
            key={`${item.MarketName} ${item.SalesAmount} ${index}`}
            className="flex flex-col gap-[8px]"
          >
            <div className="flex items-center justify-between">
              <div
                className={cn('flex items-center gap-[6px]', item.IsConnectedOff && 'opacity-30')}
              >
                <img
                  src={item.MarketIconUrl}
                  alt={'마켓 아이콘'}
                  className={'h-[24px] w-[24px] rounded-[12px]'}
                  width={24}
                  height={24}
                />
                <span className="text-v3-gray-700 Body9M14">{item.StoreName}</span>
              </div>
              {item.IsConnectedOff && (
                <div
                  className="flex items-center gap-[4px] rounded-[8px] bg-red-100 px-[6px] py-[4px]"
                  onClick={() => {
                    postMessage({
                      action: 'navigateApp',
                      data: {
                        key: 'RegisteredMarket',
                        name: 'RegisteredMarket',
                      },
                    });
                  }}
                >
                  <DisconnectIcon />
                  <span className="text-red-400 Caption1M12">다시 연결하기</span>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[6px] rounded-[10px] bg-gray-50 px-[16px] py-[12px]">
              <div className="flex items-center justify-between">
                <span className="text-v3-gray-750 Body6M14">매출</span>

                <span className="text-gray-700 Body6M14">{`${autoComma(
                  item.SalesAmount,
                )}원 (${autoComma(item.SalesCount)}건)`}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-v3-gray-750 Body6M14">정산</span>
                <span className="text-gray-700 Body6M14">{`${autoComma(
                  item.SettleAmount,
                )}원`}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarDetailModalContainer;
