import Input from '@/components/V3/Input';
import InputCloseGrayIcon from '@/assets/icons/V3/InputCloseGrayIcon.svg?react';
import { Button } from '@/components/V3/Button';
import { NotRegisteredEmailModalContainerProps } from '@/types/statistics';
import { useRef, useState } from 'react';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useToast } from '@/hooks/V3/useToast';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { setUser } from '@/redux/userSlice';
import useWebview from '@/hooks/V3/useWebview';

const NotRegisteredEmailModalContainer = ({ onClose }: NotRegisteredEmailModalContainerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const user = useAppSelector((state) => state.user);
  const [email, setEmail] = useState<string>('');
  const [isEmailValidation, setIsEmailValidation] = useState<boolean>(true);
  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const { postMessage } = useWebview();

  const { mutate: setEmailAddress } = usePost(CPLAT_API_ROUTES.setEmailAddress);
  const { mutate: getUserInfo } = usePost(CPLAT_API_ROUTES.getUserInfo);
  const { mutate: setUserInfo } = usePost(CPLAT_API_ROUTES.setUserInfo);

  const handleSetUserInfo = () => {
    setUserInfo(
      {
        ...user,
        AdAgreeYn: 'Y',
      },
      {
        onSuccess(res) {
          const { code } = res.data as CplatApiResponse<UserData[]>;
          if (code === '200') {
            handleGetUserInfo();
            // 동의 완료 후 app에 알려주기
            postMessage({
              action: 'callApi',
              data: 'getUserInfo',
            });
            onClose();
          } else {
            showToast('warning', '다시 한번 시도해주세요');
          }
        },
      },
    );
  };

  const handleSetEmailAddress = () => {
    setEmailAddress(
      {
        cplatToken: user.CplatToken,
        emailAddress: email,
      },
      {
        onSuccess(res) {
          const { code } = res.data as CplatApiResponse<UserData[]>;
          if (code === '200') {
            showToast('success', '이메일 등록이 완료되었어요!');
            // 이메일 등록 시, 이벤트 동의도 자동으로 진행
            handleSetUserInfo();
          } else {
            showToast('warning', '다시 한번 시도해주세요');
          }
        },
        onError(error) {
          showToast('warning', error.message);
          console.error(error);
        },
      },
    );
  };

  const handleGetUserInfo = () => {
    getUserInfo(
      {
        cplatToken: user.CplatToken,
      },
      {
        onSuccess(res) {
          const { data, code } = res.data as CplatApiResponse<UserData[]>;
          if (code === '200') {
            dispatch(setUser(data[0]));
          }
        },
        onError(error) {
          showToast('warning', error.message);
          console.error(error);
        },
      },
    );
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedInput = e.target.value.replace(/\s{2,}/g, ' ');
    setEmail(cleanedInput);
  };

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setIsEmailValidation(true); // 통과
      handleSetEmailAddress();
    } else {
      inputRef.current?.focus();
      setIsEmailValidation(false); // 에러
    }
  };

  return (
    <div>
      <div className="mb-[16px] text-gray-800 Title3S18">
        이메일을 등록하고 매출 파일을 받아보세요!
      </div>
      <Input
        ref={inputRef}
        className="pr-[44px]"
        placeholder="예) sellerbox@naver.com"
        value={email}
        onChange={handleInput}
        InputProps={{
          endAdornment: (
            <span
              onClick={() => {
                inputRef.current?.focus();
                setEmail('');
              }}
            >
              <InputCloseGrayIcon />
            </span>
          ),
          endAdornmentStyle: 'right-[10px]',
        }}
        Error={{
          isError: !isEmailValidation,
          errorMessage: '이메일 형식이 올바르지 않습니다.',
        }}
        type="email"
        inputMode="email"
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
      />
      <div className="my-[16px] text-v3-gray-750 Caption1M12">
        <div>확인해 주세요!</div>
        <ul className="list-disc pl-[20px]">
          <li>등록한 이메일로 매월 10일에 전월의 매출 데이터 파일이 전송돼요</li>
          <li>이메일 등록 시 마케팅 정보 수신에 동의하는 것으로 간주돼요</li>
        </ul>
      </div>
      <div className="flex gap-[9px]">
        <Button variant={'SolidLow'} height={'52'} width={'full'} onClick={onClose}>
          취소
        </Button>
        <Button
          variant={'SolidHigh'}
          height={'52'}
          width={'full'}
          onClick={handleSubmit}
          disabled={!email}
        >
          등록할게요
        </Button>
      </div>
    </div>
  );
};

export default NotRegisteredEmailModalContainer;
