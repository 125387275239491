import ArrowGrayBackwardIcon from '@/assets/icons/V3/ArrowGrayBackwardIcon.svg?react';
import useWebview from '@/hooks/V3/useWebview';
import { cn } from '@/utils/tailwind';

interface AppHeaderProps {
  title: string;
  rightHeader?: React.ReactNode;
  className?: string;
}

const AppHeader = ({ title, rightHeader, className }: AppHeaderProps) => {
  const { postMessage } = useWebview();
  return (
    <div className={cn('relative flex w-full items-center justify-center py-[18px]', className)}>
      <span
        className="absolute left-0 top-1/2 -translate-y-1/2 transform"
        onClick={() => {
          postMessage({
            action: 'backApp',
            data: 'backApp',
          });
        }}
      >
        <ArrowGrayBackwardIcon />
      </span>
      <span className="text-v3-gray-700 Body1S16">{title}</span>
      <span className="absolute right-0 top-1/2 -translate-y-1/2 transform">{rightHeader}</span>
    </div>
  );
};

export default AppHeader;
