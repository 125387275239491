import { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { ROUTES } from '@/routes';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useAppDispatch } from '@/redux/store';
import { initUser, setUser } from '@/redux/userSlice';
import GlobalSpinner from '@/components/organisms/GlobalSpinner';
import { Toaster } from '@/components/molecules/Toast';
import DocumentTitleUpdater from './hooks/DocumentTitleUpdater';
import { AES } from './utils/AES';
import Modal from './components/organisms/Modal';
import { setinquiryParams } from './redux/inquirySlice';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  gameCategoryState,
  gameScoreState,
  recentSearchKeywordListAsyncStorageState,
  safeAreaInsetsState,
  templateInquiryState,
} from './store/atom';
import Toast from './components/V3/Toast';

/**
 * 웹에서 init프로세스를 관리하는 페이지
 */
const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [sessionStorageCplatToken, setSessionStorageCplatToken] = useSessionStorage(
    'cplatToken',
    '',
  );
  const [init, setInit] = useState(false);
  const setInquiryCommentTemplate = useSetRecoilState(templateInquiryState);
  const [safeAreaInsets, setSafeAreaInsets] = useRecoilState(safeAreaInsetsState);
  const setGameCategory = useSetRecoilState(gameCategoryState);
  const setGameScore = useSetRecoilState(gameScoreState);
  const setRecentSearchKeywordList = useSetRecoilState(recentSearchKeywordListAsyncStorageState);
  // query string 조회
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const queryStringCplatToken = searchParams.get('cplatToken');
  const inquiryParams = searchParams.get('inquiryParams');
  const templateInquiryData = searchParams.get('templateInquiryData');
  const safeAreaInsetsData = searchParams.get('safeAreaInsets');
  const gameCategory = searchParams.get('gameCategory');
  const gameScore = searchParams.get('gameScore');
  const recentSearchKeywordListAsyncStorage = searchParams.get(
    'recentSearchKeywordListAsyncStorage',
  );

  const { mutate: getUserInfo } = usePost(CPLAT_API_ROUTES.getUserInfo);

  // query string -> recoil 전역 변수에 저장
  useEffect(() => {
    // 문의 상세 데이터
    if (inquiryParams) {
      const parsedParams = JSON.parse(inquiryParams);
      dispatch(setinquiryParams(parsedParams));
    }
    // 문의 템플릿 상세 데이터
    if (templateInquiryData !== null && templateInquiryData !== 'undefined') {
      const parsedParams = JSON.parse(templateInquiryData);
      setInquiryCommentTemplate(parsedParams);
    }
    // ios safearea height 값
    if (safeAreaInsetsData !== null && safeAreaInsetsData !== 'undefined') {
      const parsedParams = JSON.parse(safeAreaInsetsData);
      setSafeAreaInsets(parsedParams);
    }

    // high and low 게임 카테고리
    if (gameCategory) {
      setGameCategory(gameCategory);
    }

    // high and low 게임 스코어
    if (gameScore) {
      setGameScore(gameScore);
    }

    // 최근 검색어 목록
    if (
      recentSearchKeywordListAsyncStorage !== null &&
      recentSearchKeywordListAsyncStorage.length > 0
    ) {
      const parsedParams = JSON.parse(recentSearchKeywordListAsyncStorage);
      setRecentSearchKeywordList(parsedParams);
    }
  }, [
    inquiryParams,
    templateInquiryData,
    safeAreaInsetsData,
    gameCategory,
    gameScore,
    recentSearchKeywordListAsyncStorage,
  ]);

  // web최초 실행될때 init 로직들
  useEffect(() => {
    /** 새로고침 시 자동 로그인
     * session storage 토큰 여부 확인
     */
    if (!queryStringCplatToken && !sessionStorageCplatToken) {
      setTimeout(() => {
        setInit(true);
      }, 600);
      if (tab) {
        return navigate(`${ROUTES.signin}?tab=phone`);
      } else {
        return navigate(`${ROUTES.signin}?tab=code`);
      }
    }

    /**
     * session storage에서 가져온 암호화되어 있던 token를 복호화 후, 토큰 유효성 검사
     * Ok -> 응답값 User data 전역상태 관리 후 market으로 라우팅
     * No -> session storage에서 토큰 삭제 후 signin 으로 라우팅
     */
    const decryptedToken = AES.decryptData(sessionStorageCplatToken);

    getUserInfo(
      {
        cplatToken: queryStringCplatToken || decryptedToken,
      },
      {
        onSuccess(res) {
          const { data, code } = res.data as CplatApiResponse<UserData[]>;

          if (code === '200') {
            dispatch(setUser(data[0]));
            // index page는 현재 market
            if (pathname === '/') {
              navigate(ROUTES.market);
            } else if (pathname === '/admin') {
              // 관리자가 아닌 일반 유저의 admin 페이지 접근 제한
              if (data[0].PhoneNumber !== '01093483900') {
                setSessionStorageCplatToken('');
                dispatch(initUser());
                navigate(ROUTES.signin);
              }
            } else {
              navigate(pathname);
            }
          } else {
            setSessionStorageCplatToken('');
            navigate(ROUTES.signin);
          }

          setTimeout(() => {
            setInit(true);
          }, 400);
        },
        onError() {
          navigate(ROUTES.signin);
        },
      },
    );
  }, []);

  return (
    <Suspense fallback={<GlobalSpinner />}>
      <DocumentTitleUpdater />
      {init ? <Outlet /> : <GlobalSpinner />}
      <Toaster />
      <Modal />
      {/* V3 */}
      <Toast topInset={safeAreaInsets.topInset} />
    </Suspense>
  );
};

export default App;
