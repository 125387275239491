import Badge from '@/components/V3/Badge';
import AppHeader from '@/containers/V3/AppHeader/AppHeader';
import useWebview from '@/hooks/V3/useWebview';
import { safeAreaInsetsState } from '@/store/atom';
import { useRecoilValue } from 'recoil';
import { Button } from '@/components/V3/Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PersonalData, PersonalDataInitial, Ranking } from '@/types/discover';
import { useAppSelector } from '@/redux/store';
import { autoComma } from '@/utils/parser';

const HighAndLowRanking = () => {
  const user = useAppSelector((state) => state.user);
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);
  const { postMessage } = useWebview();
  const [rankingList, setRankingList] = useState<Array<Ranking>>([]);
  const [highScore, setHighScore] = useState<number>(0);
  const [personalData, setPersonalData] = useState<PersonalData>(PersonalDataInitial);

  const getRankingListHandler = () => {
    axios
      .get(`https://sellkey-worker.cplat.io/retrieve-up-down-game`)
      .then((res) => {
        const rankingList = res.data.body;
        const slicedList = rankingList.slice(0, 5);
        setRankingList(slicedList);
        const myRanking = rankingList.find((item: Ranking) => item.UserId === user.StoreName);
        setHighScore(myRanking.Score);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPersonalRankingHandler = () => {
    axios
      .get(`https://sellkey-worker.cplat.io/get-personal-rank?UserId=${user.StoreName}`)
      .then((res) => {
        setPersonalData(res.data.body);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getRankingListHandler();
    getPersonalRankingHandler();
  }, []);

  return (
    <main
      className={`flex h-screen select-none flex-col items-center bg-white px-[20px]`}
      style={{
        paddingTop: safeAreaInsets.topInset,
        paddingBottom: safeAreaInsets.bottomInset,
      }}
    >
      <AppHeader title={'하이앤로우 게임'} />
      <div className="flex w-full flex-grow flex-col items-center overflow-y-auto pb-[24px] scrollbar-hide">
        <div className="mt-[20px] flex self-start">
          <Badge variant={'primary'} height={'26'} width={'6'}>
            최고 점수 : {highScore}
          </Badge>
        </div>
        <div className="flex w-full flex-col items-center">
          <div className="mb-[12px] mt-[50px] text-gray-800 Title3S18">셀러님의 랭킹</div>
          <div className="flex items-center">
            <span className="text-[48px] font-bold leading-[48px]">
              {autoComma(personalData.Rank)}
            </span>
            <span className="text-[32px] font-bold leading-[32px]">위</span>
          </div>
          <div className="mt-[50px] flex w-full flex-col gap-[10px]">
            {rankingList.map((item, index) => (
              <div className="flex items-center justify-between rounded-[8px] bg-gray-100 py-[11px] pl-[12px] pr-[20px]">
                <div className="flex items-center gap-[12px]">
                  <span className="text-gray-600 Body5S14">{index + 1}</span>
                  <span className="text-gray-800 Body5S14">{item.UserId}</span>
                </div>
                <span className="text-gray-800 Body5S14">{item.Score}점</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Button
        variant={'SolidHigh'}
        width={'full'}
        height={'52'}
        onClick={() => {
          postMessage({
            action: 'resetApp',
            data: {
              index: 0,
              routes: [
                {
                  key: 'MainTab',
                  name: 'MainTab',
                  params: {
                    screen: 'DiscoverMain',
                  },
                },
                {
                  key: 'HighAndLowCategoryList',
                  name: 'HighAndLowCategoryList',
                },
              ],
            },
          });
        }}
      >
        한번 더 하기
      </Button>
    </main>
  );
};

export default HighAndLowRanking;
