import HighAndLowGame from '@/assets/images/V3/HighAndLowGame.png';
import NewsLetter from '@/assets/images/V3/NewsLetter.png';
import { cn } from '@/utils/tailwind';

type VariantType = 'highAndLowGame' | 'newsLetter';

interface ImageBannerContainerProps {
  variant: VariantType;
  title: string;
  description: string;
  onClick: () => void;
  className?: string;
}

const ImageBannerContainer = ({
  variant,
  title,
  description,
  onClick,
  className,
}: ImageBannerContainerProps) => {
  const renderImageUrl = (variant: VariantType) => {
    switch (variant) {
      case 'highAndLowGame':
        return (
          <img
            src={HighAndLowGame}
            alt="highAndLowGame"
            className="absolute right-[7px] top-1/2 h-[60px] w-[84px] -translate-y-1/2 transform"
          />
        );
      case 'newsLetter':
        return (
          <img
            src={NewsLetter}
            alt="newsLetter"
            className="absolute right-[9px] top-1/2 h-[54px] w-[61px] -translate-y-1/2"
          />
        );
    }
  };

  const getBackgroundColor = () => {
    switch (variant) {
      case 'highAndLowGame':
        return 'bg-[linear-gradient(90deg,_rgba(120,176,255,0.2)_0%,_rgba(228,131,255,0.2)_47%,_rgba(255,133,133,0.2)_100%)]';
      case 'newsLetter':
        return 'bg-v3-gray-150';
    }
  };

  return (
    <div
      className={cn(
        'relative flex justify-between rounded-[10px] p-[16px]',
        getBackgroundColor(),
        className,
      )}
      onClick={onClick}
    >
      <div>
        <div className="text-v3-gray-600 Caption1M12">{description}</div>
        <div className="text-v3-gray-800 Body3S15">{title}</div>
      </div>
      {renderImageUrl(variant)}
    </div>
  );
};

export default ImageBannerContainer;
