interface BlogItemProps {
  imageUrl: string;
  subtitle: string;
  title: string;
  onClick: () => void;
}

const BlogItem = ({ imageUrl, subtitle, title, onClick }: BlogItemProps) => {
  return (
    <div className="flex items-center gap-[12px]" onClick={onClick}>
      <img
        src={imageUrl}
        alt="blog thumbnail image"
        className="h-[80px] w-[120px] rounded-[10px] object-cover"
        height={80} // 레이아웃 움직임 제거를 위해 height, width 전달
        width={120}
      />
      <div className="flex w-full flex-col">
        <div className="text-gray-600 Caption1M12">{subtitle}</div>
        <div className="line-clamp-2 text-gray-800 Body5S14">{title}</div>
      </div>
    </div>
  );
};

export default BlogItem;
