import ChangeNameIcon from '@/assets/icons/V3/AI/ChangeNameIcon.svg?react';
import DiagnoseIcon from '@/assets/icons/V3/AI/DiagnoseIcon.svg?react';
import MakeNameIcon from '@/assets/icons/V3/AI/MakeNameIcon.svg?react';
import SellerboxAIChatbot from '@/assets/icons/V3/AI/SellerboxAIChatbot.svg?react';
import ExcelIcon from '@/assets/icons/V3/statistics/ExcelIcon.svg?react';
import MarketRegisterIcon from '@/assets/icons/V3/statistics/MarketRegisterIcon.svg?react';
import { cn } from '@/utils/tailwind';

interface IconBannerContainerProps {
  title: string;
  description: string;
  onClick: () => void;
  className?: string;
}

const IconBannerContainer = ({
  title,
  description,
  onClick,
  className,
}: IconBannerContainerProps) => {
  const renderIcon = (title: string) => {
    switch (title) {
      case '상품명 제작':
        return <MakeNameIcon />;
      case '상품 페이지 진단':
        return <DiagnoseIcon />;
      case '도매몰 상품명 바꾸기':
        return <ChangeNameIcon />;
      case '셀러박스 AI 챗봇':
        return <SellerboxAIChatbot />;
      case '매출 데이터 받아보기':
        return <ExcelIcon />;
      case '이메일 등록 완료!':
        return <ExcelIcon />;
      case '마켓을 연동해 보세요':
        return <MarketRegisterIcon />;
    }
  };
  const getBackgroundColor = () => {
    switch (title) {
      case '상품명 제작':
        return 'bg-[#78B753] bg-opacity-10';
      case '상품 페이지 진단':
        return 'bg-[#44A8C0] bg-opacity-10';
      case '도매몰 상품명 바꾸기':
        return 'bg-[#7B8BEF] bg-opacity-10';
      case '셀러박스 AI 챗봇':
        return 'bg-[#ED84D9] bg-opacity-10';
      default:
        return 'bg-gray-100';
    }
  };
  return (
    <div
      className={cn(
        'flex items-center gap-[8px] rounded-[10px] py-[16px] pl-[16px] pr-[24px]',
        getBackgroundColor(),
        className,
      )}
      onClick={onClick}
    >
      <div className="relative h-[36px] w-[36px] rounded-[18px] bg-white">
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          {renderIcon(title)}
        </span>
      </div>
      <div>
        <div className="text-gray-800 Body3S15">{title}</div>
        <div className="text-gray-750 Caption1M12">{description}</div>
      </div>
    </div>
  );
};

export default IconBannerContainer;
