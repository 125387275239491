import { gameCategoryState, safeAreaInsetsState } from '@/store/atom';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AppHeader from '@/containers/V3/AppHeader/AppHeader';
import Badge from '@/components/V3/Badge';
import GameCard from './_components/GameCard';
import { Game, PersonalData, PersonalDataInitial, SelectStatusType } from '@/types/discover';
import useWebview from '@/hooks/V3/useWebview';
import uuid from 'react-uuid';
import { useAppSelector } from '@/redux/store';

const HighAndLowGame = () => {
  const { postMessage } = useWebview();
  const gameCategory = useRecoilValue(gameCategoryState);
  const safeAreaInsets = useRecoilValue(safeAreaInsetsState);
  const [selectStatus, setSelectStatus] = useState<SelectStatusType>('default');
  const [selectedKeyword, setSelectedKeyword] = useState<string>('');
  const [score, setScore] = useState<number>(0);
  const user = useAppSelector((state) => state.user);
  const [personalData, setPersonalData] = useState<PersonalData>(PersonalDataInitial);
  const [activeGameKeywordList, setActiveGameKeywordList] = useState<Array<Game>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getGameHandler = () => {
    setIsLoading(true);
    axios
      .get(`https://sellkey-worker.cplat.io/up-down-game?category=${gameCategory}`)
      .then((res) => {
        const gameKeywordList = res.data.body;
        setActiveGameKeywordList(gameKeywordList);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setRankingHandler = () => {
    const gameId = uuid();
    axios
      .post('https://sellkey-worker.cplat.io/set-score-up-down-game', {
        UserId: user.StoreName,
        GameId: gameId,
        Score: score,
      })
      .then(() => {
        postMessage({
          action: 'resetApp',
          data: {
            index: 0,
            routes: [
              {
                key: 'MainTab',
                name: 'MainTab',
                params: {
                  screen: 'DiscoverMain',
                },
              },
              {
                key: 'HighAndLowResult',
                name: 'HighAndLowResult',
                params: {
                  gameScore: score.toString(),
                },
              },
            ],
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getPersonalRankingHandler = () => {
    axios
      .get(`https://sellkey-worker.cplat.io/get-personal-rank?UserId=${user.StoreName}`)
      .then((res) => {
        setPersonalData(res.data.body);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getWinnerKeyword = (keyword: string) => {
    if (selectStatus !== 'default') {
      return;
    }
    setSelectedKeyword(keyword);
    const maxSearchCount = Math.max(
      ...activeGameKeywordList.map((item) => item.monthly_search_count),
    );
    const selectedCount = activeGameKeywordList.find((item) => item.keyword === keyword)
      ?.monthly_search_count;
    if (maxSearchCount === selectedCount) {
      setSelectStatus('correct');
      setScore((prev) => prev + 1);
    } else {
      setSelectStatus('wrong');
    }
  };

  useEffect(() => {
    getGameHandler();
    getPersonalRankingHandler();
  }, []);

  useEffect(() => {
    if (selectStatus !== 'default') {
      setTimeout(() => {
        if (selectStatus === 'correct') {
          setSelectStatus('default');
          getGameHandler();
        } else {
          setRankingHandler();
        }
      }, 2000);
    }
  }, [selectStatus]);

  return (
    <main
      className="flex h-screen select-none flex-col gap-[12px] bg-white"
      style={{
        paddingTop: safeAreaInsets.topInset,
        paddingBottom: safeAreaInsets.bottomInset,
      }}
    >
      <div className="px-[20px]">
        <AppHeader title={'하이앤로우 게임'} />
      </div>
      <div className="overflow-y-auto scrollbar-hide">
        <div className="flex w-full flex-grow flex-col items-center px-[20px]">
          <div className="text-v3-gray-800 mb-[16px] mt-[20px] w-full whitespace-pre Title1B24">{`검색량이 높은 키워드를\n선택하세요!`}</div>
          <div className="flex w-full items-center justify-start gap-[12px]">
            <Badge height={'26'} variant={'gray'} width={'6'}>
              현재 점수 : {score}
            </Badge>
            <Badge height={'26'} variant={'primary'} width={'6'}>
              최고 점수 : {personalData.Score}
            </Badge>
          </div>
          <div className="relative h-[82px] w-full">
            {selectStatus === 'correct' && (
              <div className="absolute bottom-[16px] left-1/2 w-full -translate-x-1/2 transform text-center text-blue-400 Title3S18">
                정답입니다! 현재 점수 {score}
              </div>
            )}
            {selectStatus === 'wrong' && (
              <div className="absolute bottom-[16px] left-1/2 w-full  -translate-x-1/2 transform text-center text-red-400 Title3S18">
                틀렸습니다!
              </div>
            )}
          </div>
          <div className="flex w-full items-center gap-[13px]">
            <GameCard
              keyword={activeGameKeywordList[0]?.keyword}
              count={activeGameKeywordList[0]?.monthly_search_count}
              selectStatus={selectStatus}
              selectedKeyword={selectedKeyword}
              onClick={() => {
                getWinnerKeyword(activeGameKeywordList[0]?.keyword);
              }}
              isLoading={isLoading}
            />
            <GameCard
              keyword={activeGameKeywordList[1]?.keyword}
              count={activeGameKeywordList[1]?.monthly_search_count}
              selectStatus={selectStatus}
              selectedKeyword={selectedKeyword}
              onClick={() => {
                getWinnerKeyword(activeGameKeywordList[1]?.keyword);
              }}
              isLoading={isLoading}
            />
          </div>
          <div className="text-v3-gray-550 mt-[20px] flex self-start Caption1M12">
            네이버데이터랩 2024년 10월 데이터 기준
          </div>
        </div>
      </div>
    </main>
  );
};

export default HighAndLowGame;
